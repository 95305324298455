import React, { useEffect, useState } from "react";
import "./SignUp.scss";
import { useNavigate } from "react-router-dom";
import { auth, provider } from "../../firebase";
import {
  signInWithPopup,
  createUserWithEmailAndPassword,
  updateProfile,
  sendEmailVerification,
} from "@firebase/auth";
import { Alert, Button, IconButton } from "@mui/material";
import { actionTypes } from "../../Reducer";
import { useStateValue } from "../../StateProvider";
import RequiredInput from "../../Components/Buttons/RequiredInput";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import VisibilityIcon from "@mui/icons-material/Visibility";

function SignUp() {
  const history = useNavigate();
  const [{}, dispatch] = useStateValue();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [activeEye, setActiveEye] = useState(false);
  const [loginError, setLoginError] = useState("");

  const handleLogin = (e) => {
    e.preventDefault();
    const form = document.querySelector(".signUp-form");

    if (form.checkValidity() === false) {
      if (document.getElementById("email").checkValidity() === false) {
        document.getElementById("email").setAttribute("focused", "true");
      }
      if (document.getElementById("password").checkValidity() === false) {
        document.getElementById("password").setAttribute("focused", "true");
      }
      if (document.getElementById("firstName").checkValidity() === false) {
        document.getElementById("firstName").setAttribute("focused", "true");
      }
      if (document.getElementById("lastName").checkValidity() === false) {
        document.getElementById("lastName").setAttribute("focused", "true");
      }
    } else {
      createUserWithEmailAndPassword(auth, email, password)
        .then(async (result) => {
          await updateProfile(result.user, {
            displayName: firstName + " " + lastName,
          });
          await sendEmailVerification(result.user);
          dispatch({
            type: actionTypes.SET_LOGIN_USER,
            user: result.user,
            displayName: firstName + " " + lastName,
          });
        })
        .catch((error) => {
          console.log(error);
          if (
            error === "FirebaseError: Firebase: Error (auth/invalid-email)."
          ) {
            setLoginError(
              "Invalid email address. Please try again with a valid email address."
            );
          }
          setLoginError(
            "This email is already registered. Please try again with a different email address."
          );
        });
    }
  };

  const handleSignInWithGoogle = (e) => {
    e.preventDefault();
    signInWithPopup(auth, provider)
      .then((user) => {
        dispatch({
          type: actionTypes.SET_LOGIN_USER,
          user: user.user,
          displayName: user.user.displayName,
        });
      })
      .catch((error) => {
        setLoginError(
          "Seems like there was some problem logging in using the google services. Please try again"
        );
      });
  };

  const hide_viewPassword = () => {
    const passwordType = document.querySelector("#password");
    try {
      const type =
        passwordType.getAttribute("type") === "password" ? "text" : "password";
      if (type === "text") {
        setActiveEye(true);
      } else {
        setActiveEye(false);
      }
      passwordType.setAttribute("type", type);
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    document.title = "Sign Up | Patel Taxation and Accounting";
  }, []);

  document.getElementById("email")?.addEventListener("input", (event) => {
    if (loginError !== "") {
      setLoginError("");
    }
  });

  document.getElementById("password")?.addEventListener("input", (event) => {
    if (loginError !== "") {
      setLoginError("");
    }
  });

  return (
    <div className="login">
      <div className="loginCard">
        <div className="signIn-container">
          <h1>Create an account</h1>
          <p>
            Create an account to access your account and view your profile,
            appointments and more.
          </p>
          <form className="signUp-form">
            <div className="firstName-field">
              <label htmlFor="email">First Name</label>
              <RequiredInput
                inputType="input"
                id="firstName"
                placeholder="First Name"
                currValue={firstName}
                type="text"
                onChangeVariable={setFirstName}
                pattern={"^[a-zA-Z0-9 ]+$"}
                errorMessage={"First name is a required field"}
                required
              />
            </div>
            <div className="lastName-field">
              <label htmlFor="email">Last Name</label>
              <RequiredInput
                inputType="input"
                id="lastName"
                placeholder="Last Name"
                currValue={lastName}
                type="text"
                onChangeVariable={setLastName}
                pattern={"^[a-zA-Z0-9 ]+$"}
                errorMessage={"First name is a required field"}
                required
              />
            </div>
            <div className="email-field">
              <label htmlFor="email">Email Address</label>
              <RequiredInput
                id="email"
                inputType="input"
                currValue={email}
                type="email"
                placeholder="Email"
                onChangeVariable={setEmail}
                pattern="^[a-zA-Z0-9+_.-]+@[a-zA-Z0-9.-]+$"
                errorMessage={"Email is a required field"}
                required
              />
            </div>
            <div>
              <label htmlFor="password">Password</label>
              <div className="password-field">
                <RequiredInput
                  id="password"
                  inputType="input"
                  type="password"
                  placeholder="Password"
                  currValue={password}
                  onChangeVariable={setPassword}
                  pattern="(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}"
                  errorMessage={"Password is a required field"}
                  required
                />
                <IconButton
                  onClick={hide_viewPassword}
                  className="password-icon"
                >
                  {activeEye ? <VisibilityIcon /> : <VisibilityOffIcon />}
                </IconButton>
              </div>
            </div>
            {loginError && <Alert severity="error">{loginError}</Alert>}
            <div className="signIn-actionBtn">
              <Button
                variant="contained"
                className="actionBtn"
                type="submit"
                onClick={handleLogin}
              >
                Sign Up
              </Button>
              <Button
                variant="contained"
                className="actionBtn"
                onClick={handleSignInWithGoogle}
              >
                <img
                  alt="google"
                  src="/images/google.png"
                  width="30"
                  height="30"
                />
                Sign Up With Google
              </Button>
            </div>
          </form>
        </div>
        <div className="needToRegister">
          <a href="/login">Already have an account?</a>
        </div>
      </div>
    </div>
  );
}

export default SignUp;
