import { Button } from "@mui/material";
import { collection, doc, getDoc, writeBatch } from "firebase/firestore";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import db, { auth, storage } from "../../firebase";
import { useStateValue } from "../../StateProvider";
import { encryptBlob, encryptText } from "../../helper/encryption";
import { getDownloadURL, ref, uploadBytes } from "firebase/storage";
import FileInput from "../../Components/Form/FileInput";
import RequiredInput from "../../Components/Buttons/RequiredInput";

// TODO: Add validation for all fields, add error handling for uploading files, add error handling for downloading files, add progress bar for uploading files
function PersonalApplication() {
  const navigate = useNavigate();
  const year = Number(useParams().year);
  const currentTaxYear = Number(new Date().getFullYear() - 1); // convert to number
  const [{ user, emailVerified }, dispatch] = useStateValue();
  const [clientDocuments, setClientDocuments] = useState({
    yourself: {
      t4: {},
      t2202: {},
      t5: {},
      t4a: {},
      voidCheque: {},
      otherDocuments: {},
    },
    spouse: {
      t4: {},
      t2202: {},
      t5: {},
      t4a: {},
      voidCheque: {},
      otherDocuments: {},
    },
    dependents: {},
  });
  const [office, setOffice] = useState("Scarborough");
  const [referralCode, setReferralCode] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [dateOfBirth, setDateOfBirth] = useState("");
  const [sin, setSin] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [email, setEmail] = useState("");
  const [dateOfArrival, setDateOfArrival] = useState("");
  const [address, setAddress] = useState("");
  const [city, setCity] = useState("");
  const [province, setProvince] = useState("");
  const [postalCode, setPostalCode] = useState("");
  const [dateOfAddressChange, setDateOfAddressChange] = useState("");
  const [maritalStatus, setMaritalStatus] = useState("Single"); // Single, Married, Divorced, Widowed, Living Common Law
  const [dateOfMaritalStatusChange, setDateOfMaritalStatusChange] =
    useState("");
  const [marriedInfo, setMarriedInfo] = useState({
    spouseName: "",
    spouseDateOfBirth: "",
    spouseEmail: "",
    spousePhoneNumber: "",
    spouseSin: "",
  });
  const [dependents, setDependents] = useState([]);
  const [rent, setRent] = useState({
    amount: 0,
    months: 0,
  });
  const [filedYears, setFiledYears] = useState([]);
  const [status, setStatus] = useState("");

  const documentTypes = [
    "t4",
    "t2202",
    "t5",
    "t4a",
    "voidCheque",
    "otherDocuments",
  ];

  // References to the documents in the database
  const userCollectionRef = doc(collection(db, "users"), user?.uid);
  const yearPersonalApplication = doc(
    collection(db, "users", user?.uid, "taxApplications"),
    year.toString()
  );
  const currentYearPersonalApplicationRef = doc(
    collection(db, "applications", "personal", currentTaxYear.toString())
  );

  useEffect(() => {
    window.scrollTo(0, 0);
    document.title = "Personal Application | Patel Accounting";

    const fetchFiledYears = async () => {
      let checkIfFiled = [];
      await getDoc(userCollectionRef)
        .then((doc) => {
          if (doc.exists()) {
            checkIfFiled = doc.data().filedPersonalApplication;
            setFiledYears(checkIfFiled);
          }
        })
        .finally(() => {
          if (
            (year < currentTaxYear && !checkIfFiled.includes(year)) ||
            year > currentTaxYear
          ) {
            throw new Error("Cannot access this page");
          }
        });
    };
    if (emailVerified) {
      fetchFiledYears();
    } else {
      navigate("/dashboard");
    }
  }, []);

  useEffect(() => {
    if (filedYears) {
      if (filedYears.length === 0) {
        setStatus("firstTime");
      } else {
        setStatus("loadPreviousYearData");
      }
    }
  }, [filedYears]);

  useEffect(() => {
    const extractData = async () => {
      dispatch({
        type: "SET_LOADING",
        loading: true,
      });
      await getDoc(userCollectionRef).then((doc) => {
        setFirstName(doc.data().firstName);
        setLastName(doc.data().lastName);
        setDateOfBirth(doc.data().dateOfBirth);
        setPhoneNumber(doc.data().phoneNumber);
        setEmail(doc.data().email);
        setDateOfArrival(doc.data().dateOfArrival);
        setMaritalStatus(doc.data().maritalStatus);
        setDateOfMaritalStatusChange(doc.data().dateOfMaritalStatusChange);
        const updatedMarriedInfo = {
          ...doc.data().marriedInfo,
          spouseSin: "",
        };
        setMarriedInfo(updatedMarriedInfo); // convert SIN to empty string
        const updatedDependents = doc.data().dependents.map((dependent) => {
          return {
            ...dependent,
            sin: "",
          };
        });
        setDependents(updatedDependents);
        dispatch({
          type: "SET_LOADING",
          loading: false,
        });
      });
    };
    if (status === "loadPreviousYearData") {
      extractData();
    }
  }, [status]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    // Validate the form
    if (e.target.checkValidity() === true) {
      dispatch({
        type: "SET_LOADING",
        loading: true,
      });

      let result = false;
      let promises = [];
      for (const person in clientDocuments) {
        for (const documentType in clientDocuments[person]) {
          for (const name in clientDocuments[person][documentType]) {
            const promise = uploadToFirebaseStorage(
              clientDocuments,
              person,
              documentType,
              name
            );
            promises.push(promise);
          }
        }
      }

      await Promise.all(promises)
        .then(() => {
          result = true;
        })
        .catch((error) => {
          // console.error("Error uploading files: ", error);
        });

      if (result) {
        const batch = writeBatch(db);
        const updatedMarriedInfo = {
          ...marriedInfo,
          spouseSin: encryptText(marriedInfo.spouseSin),
        };

        const updatedDependents = dependents.map((dependent) => {
          return {
            ...dependent,
            sin: encryptText(dependent.sin),
          };
        });

        batch.update(userCollectionRef, {
          filedPersonalApplication: [...filedYears, year],
          dateOfBirth,
          phoneNumber,
          email,
          dateOfArrival,
          address,
          city,
          province,
          postalCode,
          maritalStatus,
          dateOfMaritalStatusChange,
          marriedInfo: updatedMarriedInfo,
          dependents: updatedDependents,
        });

        batch.set(currentYearPersonalApplicationRef, {
          accountantDocuments: {},
          clientDocuments,
          office,
          requireReviseDocuments: false,
          status: "To Do",
          assignedTo: "",
          note: "",
          referralCode,
          firstName,
          lastName,
          dateOfBirth,
          phoneNumber,
          email,
          dateOfArrival,
          address,
          city,
          province,
          postalCode,
          dateOfAddressChange,
          maritalStatus,
          dateOfMaritalStatusChange,
          marriedInfo: updatedMarriedInfo,
          dependents: updatedDependents,
          rent,
          sin: encryptText(sin),
          uid: auth.currentUser.uid,
          submittedOn: new Date(),
        });

        batch.set(yearPersonalApplication, {
          personal: {
            applicationId: currentYearPersonalApplicationRef.id,
            submittedOn: new Date(),
            status: "In Progress",
            accountantUploaded: false,
            requestedRevisedApplication: false,
          },
        });

        if (
          referralCode !== "" &&
          referralCode !== user.referralCode &&
          office === "Oshawa"
        ) {
          const referralRef = doc(
            collection(db, "referralCodes"),
            referralCode
          );
          let count, validReferralCode;
          await getDoc(referralRef)
            .then((doc) => {
              count =
                doc.data()[`${currentTaxYear}_count`] === undefined
                  ? 0
                  : doc.data()[`${currentTaxYear}_count`] + 1;
              validReferralCode = true;
            })
            .catch((error) => {
              console.log("Invalidate referral code!");
              validReferralCode = false;
            });

          if (validReferralCode) {
            batch.update(referralRef, {
              [`${currentTaxYear}_count`]: count + 1,
            });
          }
        }

        await batch
          .commit()
          .then(() => {
            dispatch({
              type: "SET_LOADING",
              loading: false,
            });
            navigate("/");
          })
          .catch((error) => {
            dispatch({
              type: "SET_LOADING",
              loading: false,
            });
            console.log(error);
          });
      } else {
        console.log("Error uploading to Firebase Storage");
      }
    } else {
      e.target.reportValidity();
    }
  };

  const uiEncryptSIN = (sin) => {
    let encryptedSIN = "";
    for (let i = 0; i < sin.length; i++) {
      if (i < 3 || i > 6) {
        encryptedSIN += sin[i];
      } else {
        encryptedSIN += "*";
      }
    }
    return encryptedSIN;
  };

  const handleFileUpload = (e, section, documentType) => {
    e.preventDefault();
    const newClientDocuments = { ...clientDocuments };
    for (let i = 0; i < e.target.files.length; i++) {
      const file = e.target.files[i];
      const fileName = file.name;

      try {
        newClientDocuments[section][documentType][fileName] = file;
      } catch (error) {
        newClientDocuments[section][documentType] = {
          ...newClientDocuments[section][documentType],
          [fileName]: file,
        };
      }
    }
    // Update the state after all promises have resolved
    setClientDocuments(newClientDocuments);
  };

  const handleFileRemove = (e, section, documentType, name) => {
    e.preventDefault();
    const newClientDocuments = { ...clientDocuments };
    delete newClientDocuments[section][documentType][name];
    setClientDocuments(newClientDocuments);
  };

  const uploadToFirebaseStorage = async (
    clientDocuments,
    person,
    documentType,
    name
  ) => {
    const blob = clientDocuments[person][documentType][name];
    const storageRef = ref(
      storage,
      `/${year.toString()}/${auth.currentUser.uid}/personalApplication/${name}`
    );
    // Encrypt the blob before uploading
    await encryptBlob(blob, auth.currentUser.uid).then(
      async (encryptedBlob) => {
        // Upload the encrypted blob to Firebase Storage
        await uploadBytes(storageRef, encryptedBlob)
          .then(async (snapshot) => {
            await getDownloadURL(snapshot.ref).then(
              (url) => (clientDocuments[person][documentType][name] = url)
            );
          })
          .catch((error) => {
            console.log(`Unable to upload ${name}. Error: `, error);
            throw new Error(`Unable to upload ${name}`);
          });
      }
    );
  };

  return (
    <div style={{ padding: "30px 10px" }}>
      <h2>Personal Application {year}</h2>
      <form onSubmit={handleSubmit} className="form-container">
        <h2>Select closest office</h2>
        <div className="form-section">
          <div className="form-input">
            <label htmlFor="office">Office</label>
            <select
              name="office"
              id="office"
              value={office}
              onChange={(e) => setOffice(e.target.value)}
            >
              <option value="Scarborough">Scarborough</option>
              <option value="Oshawa">Oshawa</option>
            </select>
          </div>
          {office === "Oshawa" && (
            <div className="form-input">
              <label htmlFor="referralCode">Referral Code</label>
              <input
                type="text"
                name="referralCode"
                id="referralCode"
                value={referralCode}
                onChange={(e) => {
                  e.preventDefault();
                  setReferralCode(e.target.value);
                }}
              />
            </div>
          )}
        </div>
        <h2>Personal Information</h2>
        <div className="form-section">
          <div className="form-input">
            <label htmlFor="name">First Name</label>
            <RequiredInput
              inputType={"input"}
              type={"text"}
              id="name"
              value={firstName}
              label="First Name"
              onChangeVariable={setFirstName}
              required
              errorMessage={"First Name is required"}
            />
          </div>
          <div className="form-input">
            <label htmlFor="name">Last Name</label>
            <RequiredInput
              inputType={"input"}
              type={"text"}
              id="name"
              value={lastName}
              label="Last Name"
              onChangeVariable={setLastName}
              required
              errorMessage={"Last Name is required"}
            />
          </div>
          <div className="form-input">
            <label htmlFor="dateOfBirth">Date of Birth</label>
            <RequiredInput
              inputType={"date"}
              type={"date"}
              id="dateOfBirth"
              value={dateOfBirth}
              onChangeVariable={setDateOfBirth}
              required
              errorMessage={"Date of Birth is required"}
            />
          </div>
          <div className="form-input">
            <label htmlFor="phoneNumber">Phone Number</label>
            <RequiredInput
              type={"tel"}
              inputType={"phoneNumber"}
              id="phoneNumber"
              value={phoneNumber}
              onChangeVariable={setPhoneNumber}
              required
              errorMessage={"Phone Number is required"}
            />
          </div>
          <div className="form-input">
            <label htmlFor="email">Email</label>
            <RequiredInput
              inputType={"input"}
              type={"email"}
              id="email"
              value={email}
              onChangeVariable={setEmail}
              required
              pattern={"[a-zA-Z0-9._%+-]+@[a-z0-9.-]+.[a-z]{2,}$"}
              errorMessage={"Email is required and must be valid"}
            />
          </div>
          <div className="form-input">
            <label htmlFor="sin">SIN</label>
            {/* Encrypt SIN after writing */}
            <input
              type="text"
              name="sin"
              id="sin"
              onBlur={(e) => {
                e.target.value = uiEncryptSIN(sin);
              }}
              onFocus={(e) => {
                e.target.value = sin;
              }}
              onChange={(e) => setSin(e.target.value)}
            />
          </div>
          <div className="form-input">
            <label htmlFor="dateOfArrival">Date of Arrival in Canada</label>
            <RequiredInput
              inputType={"date"}
              type={"date"}
              id="dateOfArrival"
              value={dateOfArrival}
              onChangeVariable={setDateOfArrival}
            />
          </div>
        </div>

        <h2>Mailing Address</h2>
        <div className="form-section">
          <div className="form-input">
            <label htmlFor="address">
              Address - Street No Street Name, PO Box, RR
            </label>
            <RequiredInput
              inputType={"input"}
              type={"text"}
              id="address"
              value={address}
              onChangeVariable={setAddress}
              required
              errorMessage={"Address is required field"}
            />
          </div>
          <div className="form-input">
            <label htmlFor="city">City</label>
            <RequiredInput
              inputType={"input"}
              type={"text"}
              id="city"
              value={city}
              onChangeVariable={setCity}
              required
              errorMessage={"City is required field"}
            />
          </div>
          <div className="form-input">
            <label htmlFor="province">Province</label>
            <RequiredInput
              inputType={"input"}
              type={"text"}
              id="province"
              value={province}
              onChangeVariable={setProvince}
              required
              errorMessage={"Province is required field"}
            />
          </div>
          <div className="form-input">
            <label htmlFor="postalCode">Postal Code</label>
            <RequiredInput
              inputType={"input"}
              type={"text"}
              id="postalCode"
              value={postalCode}
              onChangeVariable={setPostalCode}
              required
              errorMessage={"Postal Code is required field"}
            />
          </div>
          <div className="form-input">
            <label htmlFor="addressChange">Date of Address Change</label>
            <RequiredInput
              inputType={"date"}
              type={"date"}
              id="addressChange"
              value={dateOfAddressChange}
              onChangeVariable={setDateOfAddressChange}
            />
          </div>
        </div>

        <h2>Rental Information</h2>
        <div className="form-section">
          <div className="form-input">
            <label htmlFor="rent">Total amount per year</label>
            <RequiredInput
              inputType={"amount"}
              type={"number"}
              id="rent"
              value={rent.amount}
              onChangeVariable={(value) =>
                setRent({ ...rent, amount: Number(value) })
              }
              pattern={"[0-9]+"}
              errorMessage={"Rent amount must be a number greater than 0"}
            />
          </div>
          <div className="form-input">
            <label htmlFor="months">Total months to claim</label>
            <RequiredInput
              inputType={"input"}
              type={"number"}
              id="months"
              value={rent.months}
              min={0}
              max={12}
              onChangeVariable={(value) =>
                setRent({ ...rent, months: Number(value) })
              }
              pattern={"[0-9]+"}
              errorMessage={"Months must be a number between 0 and 12"}
            />
          </div>
        </div>

        <h2>Marital Information</h2>
        <div className="form-section">
          <div className="form-input">
            <label htmlFor="maritalStatus">Marital Status</label>
            <select
              name="maritalStatus"
              id="maritalStatus"
              value={maritalStatus}
              onChange={(e) => setMaritalStatus(e.target.value)}
            >
              <option value="Single">Single</option>
              <option value="Married">Married</option>
              <option value="Divorced">Divorced</option>
              <option value="Widowed">Widowed</option>
              <option value="Living Common-Law">Living Common Law</option>
            </select>
          </div>
          {maritalStatus !== "Single" && (
            <div className="form-input">
              <label htmlFor="dateOfMaritalStatusChange">
                Date of Marital Status Change
              </label>
              <RequiredInput
                inputType={"date"}
                type={"date"}
                id="dateOfMaritalStatusChange"
                value={dateOfMaritalStatusChange}
                onChangeVariable={setDateOfMaritalStatusChange}
                errorMessage={"Date of Marital Status Change is required"}
                required
              />
            </div>
          )}
          {maritalStatus === "Married" && (
            <>
              <div className="form-input">
                <label htmlFor="spouseName">Spouse Name</label>
                <RequiredInput
                  inputType={"input"}
                  type={"text"}
                  id="spouseName"
                  value={marriedInfo.spouseName}
                  onChangeVariable={(value) =>
                    setMarriedInfo({ ...marriedInfo, spouseName: value })
                  }
                  errorMessage={"Spouse Name is required"}
                  required
                />
              </div>
              <div className="form-input">
                <label htmlFor="spouseDateOfBirth">Spouse Date of Birth</label>
                <RequiredInput
                  inputType={"date"}
                  type={"date"}
                  id="spouseDateOfBirth"
                  value={marriedInfo.spouseDateOfBirth}
                  onChangeVariable={(value) =>
                    setMarriedInfo({ ...marriedInfo, spouseDateOfBirth: value })
                  }
                  errorMessage={"Spouse Date of Birth is required"}
                  required
                />
              </div>
              <div className="form-input">
                <label htmlFor="spouseEmail">Spouse Email</label>
                <RequiredInput
                  inputType={"input"}
                  type={"email"}
                  id="spouseEmail"
                  value={marriedInfo.spouseEmail}
                  onChangeVariable={(value) =>
                    setMarriedInfo({ ...marriedInfo, spouseEmail: value })
                  }
                  pattern={"[a-zA-Z0-9._%+-]+@[a-z0-9.-]+.[a-z]{2,}$"}
                  required
                  errorMessage={"Spouse Email is required"}
                />
              </div>
              <div className="form-input">
                <label htmlFor="spousePhoneNumber">Spouse Phone Number</label>
                <RequiredInput
                  inputType={"phoneNumber"}
                  type={"tel"}
                  id="spousePhoneNumber"
                  value={marriedInfo.spousePhoneNumber}
                  onChangeVariable={(value) =>
                    setMarriedInfo({ ...marriedInfo, spousePhoneNumber: value })
                  }
                  required
                  errorMessage={"Spouse Phone Number is required"}
                />
              </div>
              <div className="form-input">
                <label htmlFor="spouseSin">Spouse SIN</label>
                <input
                  type="text"
                  name="spouseSin"
                  id="spouseSin"
                  onBlur={(e) => {
                    e.target.value = uiEncryptSIN(marriedInfo.spouseSin);
                  }}
                  onFocus={(e) => {
                    e.target.value = marriedInfo.spouseSin;
                  }}
                  onChange={(e) =>
                    setMarriedInfo({
                      ...marriedInfo,
                      spouseSin: e.target.value,
                    })
                  }
                />
              </div>
            </>
          )}
        </div>

        <div className="form-header">
          <h2>Dependents</h2>
          <Button
            type="button"
            onClick={(e) => {
              e.preventDefault();
              setDependents([
                ...dependents,
                {
                  name: "",
                  dateOfBirth: "",
                  sin: "",
                },
              ]);
            }}
          >
            <span id="additional_buttons">
              <img alt="add" src="/images/dashboard/add.png" id="addButton" />
              <p>Add Dependent</p>
            </span>
          </Button>
        </div>
        <div className="form-section">
          <div className="form-input">
            {dependents.map((dependent, index) => (
              <div key={index} className="form-section">
                <div className="form-input">
                  <label htmlFor="name">Name</label>
                  <RequiredInput
                    inputType={"input"}
                    type={"text"}
                    id="name"
                    value={dependent.name}
                    onChangeVariable={(value) => {
                      const newDependents = [...dependents];
                      newDependents[index].name = value;
                      setDependents(newDependents);
                    }}
                    required
                    errorMessage={"Name is required"}
                  />
                </div>
                <div className="form-input">
                  <label htmlFor="dateOfBirth">Date of Birth</label>
                  <RequiredInput
                    inputType={"date"}
                    type={"date"}
                    id="dateOfBirth"
                    value={dependent.dateOfBirth}
                    onChangeVariable={(value) => {
                      const newDependents = [...dependents];
                      newDependents[index].dateOfBirth = value;
                      setDependents(newDependents);
                    }}
                    required
                    errorMessage={"Date of Birth is required"}
                  />
                </div>
                <div className="form-input">
                  <label htmlFor="sin">SIN</label>
                  <input
                    type="text"
                    name="sin"
                    id="sin"
                    onBlur={(e) => {
                      e.target.value = uiEncryptSIN(dependent.sin);
                    }}
                    onFocus={(e) => {
                      e.target.value = dependent.sin;
                    }}
                    onChange={(e) => {
                      const newDependents = [...dependents];
                      newDependents[index].sin = e.target.value;
                      setDependents(newDependents);
                    }}
                  />
                </div>
              </div>
            ))}
          </div>
        </div>

        <h2>Tax Documents</h2>
        <div className="form-section">
          {documentTypes.map((documentType, index) => (
            <FileInput
              key={index}
              label={documentType}
              section="yourself"
              documentType={documentType}
              status={status}
              handleFileUpload={handleFileUpload}
              handleFileRemove={handleFileRemove}
              uploadedFiles={clientDocuments.yourself[documentType]}
            />
          ))}
        </div>

        {maritalStatus === "Married" && (
          <>
            <h2>Spouse's Documents</h2>
            <div className="form-section">
              {documentTypes.map((documentType, index) => (
                <FileInput
                  key={index}
                  label={documentType}
                  section="spouse"
                  documentType={documentType}
                  status={status}
                  handleFileUpload={handleFileUpload}
                  handleFileRemove={handleFileRemove}
                  uploadedFiles={clientDocuments.spouse[documentType]}
                />
              ))}
            </div>
          </>
        )}

        {dependents.length > 0 && (
          <>
            <h2>Dependents Documents</h2>
            {dependents.map(
              (dependent, index) =>
                dependent.name !== "" && (
                  <div key={index}>
                    <h3 style={{ marginBottom: "10px" }}>{dependent.name}</h3>
                    <div className="form-section">
                      {documentTypes.map((documentType, index) => (
                        <FileInput
                          key={index}
                          label={documentType}
                          section="dependents"
                          documentType={documentType}
                          status={status}
                          handleFileUpload={handleFileUpload}
                          handleFileRemove={handleFileRemove}
                          uploadedFiles={
                            clientDocuments.dependents[documentType]
                          }
                        />
                      ))}
                    </div>
                  </div>
                )
            )}
          </>
        )}
        <div
          className="button_container"
          style={{ margin: "0 auto", marginTop: "30px", gap: "15px" }}
        >
          <Button
            variant="outlined"
            color="error"
            onClick={() => navigate("/")}
            style={{ width: "fit-content" }}
          >
            Cancel
          </Button>

          <Button
            variant="contained"
            type="submit"
            style={{ width: "fit-content" }}
          >
            Submit Application
          </Button>
        </div>
      </form>
    </div>
  );
}

export default PersonalApplication;
