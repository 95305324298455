import { Button } from "@mui/material";
import React, { useEffect, useState } from "react";
import "./Popup.scss";
import { auth } from "../../firebase";
import { sendEmailVerification } from "firebase/auth";

function EmailVerificationReq({ setTogglePopup }) {
  const [emailTimer, setEmailTimer] = useState(0);

  const resendEmailVerification = () => {
    sendEmailVerification(auth.currentUser)
      .then(() => {
        console.log("Email sent");
        setEmailTimer(60);
      })
      .catch((error) => {
        console.log("Error: ", error);
      });
  };

  useEffect(() => {
    if (emailTimer > 0) {
      setTimeout(() => {
        setEmailTimer(emailTimer - 1);
      }, 1000);
    }
  }, [emailTimer]);

  return (
    <div className="popup-container" style={{ marginTop: "0px" }}>
      <div className="model_container">
        <h1 style={{ textAlign: "center", marginBottom: "1rem" }}>
          Verify your email
        </h1>
        <p>
          Please verify your account through the email address you provided
          during registration. Once that process is complete, refresh the
          website to enable remote tax document submission.
        </p>
        <p>
          If you have not received the email, please check your spam folder or
          request another email verification.
        </p>

        <div className="button_container">
          <Button
            variant="outlined"
            onClick={() => setTogglePopup(false)}
            style={{ margin: "0.5rem 0.5rem" }}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            onClick={resendEmailVerification}
            style={{ margin: "0.5rem 0.5rem" }}
            disabled={emailTimer > 0}
          >
            {emailTimer > 0 ? `${emailTimer} seconds` : "Resend Email"}
          </Button>
        </div>
      </div>
    </div>
  );
}

export default EmailVerificationReq;
