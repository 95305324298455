import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import "ag-grid-community/styles/ag-theme-quartz.css";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { collection, getDocs, query, where } from "firebase/firestore";
import db from "../../../firebase";
import { AgGridReact } from "ag-grid-react";

function Referrals() {
  const gridRef = useRef(null);
  const [referrals, setReferrals] = useState([]);
  const [year, setYear] = useState(new Date().getFullYear() - 1);
  const columns = [
    {
      field: "firstName",
      headerName: "First Name",
      sortable: true,
      filter: true,
    },
    {
      field: "lastName",
      headerName: "Last Name",
      sortable: true,
      filter: true,
    },
    {
      field: "email",
      headerName: "Email",
      sortable: true,
      filter: true,
    },
    {
      field: `${year}_count`,
      headerName: "Referrals",
      sortable: true,
      filter: true,
    },
    {
      field: "code",
      headerName: "Referral Code",
      sortable: true,
      filter: true,
    },
  ];
  const onGridReady = useCallback((params) => {
    if (window.innerWidth >= 1170) {
      sizeToFit();
    }
  }, []);
  const sizeToFit = useCallback(() => {
    gridRef.current.api.sizeColumnsToFit({
      defaultMinWidth: 200,
    });
  }, []);

  useEffect(() => {
    const fetchReferrals = async () => {
      const referralRef = collection(db, "referralCodes");
      const q = query(referralRef, where(`${year}_count`, ">", 0));

      // Fetch the referrals from the database
      const querySnapshot = await getDocs(q);
      setReferrals([]);
      querySnapshot.forEach((doc) => {
        let data = doc.data();
        data.code = doc.id;
        setReferrals((prev) => [...prev, data]);
      });
    };
    fetchReferrals();
  }, [year]);

  return (
    <div style={{ padding: "10px" }}>
      <h1>Referrals</h1>
      {/* Filters */}
      <div style={{ display: "flex", gap: "10px", margin: "10px 0px" }}>
        <input
          type="number"
          value={year}
          onChange={(e) => setYear(e.target.value)}
        />
        <button>Filter</button>
      </div>

      {/* Referrals List */}
      <div style={{ height: "70vh", width: "100%" }}>
        <div className="ag-theme-quartz" style={{ height: "100%" }}>
          <AgGridReact
            ref={gridRef}
            rowData={referrals}
            columnDefs={columns}
            onGridReady={onGridReady}
            onGridSizeChanged={sizeToFit}
            animateRows={true}
            cacheQuickFilter={true}
            suppressScrollOnNewData={true}
          ></AgGridReact>
        </div>
      </div>
    </div>
  );
}

export default Referrals;
