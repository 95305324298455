import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useStateValue } from "../../StateProvider";
import {
  collection,
  doc,
  onSnapshot,
  updateDoc,
  writeBatch,
} from "firebase/firestore";
import db from "../../firebase";
import { Button, IconButton } from "@mui/material";
import FileInput from "../../Components/Form/FileInput";
import { decryptBlob, decryptText } from "../../helper/encryption";
import JSZip from "jszip";
import UpdateStatus from "../../Components/Popup/UpdateStatus";
import DeleteIcon from "@mui/icons-material/Delete";
import "../Dashboard/Dashboard.scss";

function ViewPersonalApplication() {
  const personalApplicationId = useParams().id;
  const taxYear = useParams().year;
  const [{ user, role }, dispatch] = useStateValue();
  const [decryptionUID, setDecryptionUID] = useState("");
  const [clientUID, setClientUID] = useState("");
  const [accountantDocuments, setAccountantDocuments] = useState({});
  const [clientDocuments, setClientDocuments] = useState({});
  const [currentOffice, setCurrentOffice] = useState("");
  const [referralCode, setReferralCode] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [dateOfBirth, setDateOfBirth] = useState("");
  const [sin, setSin] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [email, setEmail] = useState("");
  const [dateOfArrival, setDateOfArrival] = useState("");
  const [address, setAddress] = useState("");
  const [city, setCity] = useState("");
  const [province, setProvince] = useState("");
  const [postalCode, setPostalCode] = useState("");
  const [dateOfAddressChange, setDateOfAddressChange] = useState("");
  const [maritalStatus, setMaritalStatus] = useState("");
  const [dateOfMaritalStatusChange, setDateOfMaritalStatusChange] =
    useState("");
  const [marriedInfo, setMarriedInfo] = useState({});
  const [dependents, setDependents] = useState([]);
  const [currentRequireReviseDocuments, setCurrentRequireReviseDocuments] =
    useState(false);
  const [toggleUpdateStatus, setToggleUpdateStatus] = useState(false);
  const [rent, setRent] = useState({
    amount: 0,
    months: 0,
  });
  const yearPersonalApplication = doc(
    collection(db, "applications", "personal", taxYear),
    personalApplicationId
  );
  const [applicationStatus, setApplicationStatus] = useState("");
  const [applicationNote, setApplicationNote] = useState("");
  const [applicationAssignedTo, setApplicationAssignedTo] = useState("");
  const [updatedFields, setUpdatedFields] = useState({});
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);

  const documentTypes = [
    "t4",
    "t2202",
    "t5",
    "t4a",
    "voidCheque",
    "otherDocuments",
  ];

  useEffect(() => {
    window.scrollTo(0, 0);
    document.title =
      "View Personal Application | Patel Accounting and Taxation";

    const unsubscribe = onSnapshot(yearPersonalApplication, async (doc) => {
      if (doc.exists()) {
        const data = doc.data();
        let sin, updatedMarriedInfo, updatedDependents;
        if (role === "Admin") {
          setDecryptionUID(data.uid);
          sin = await decryptText(data.sin, data.uid);
          updatedMarriedInfo = {
            ...data.marriedInfo,
            spouseSin:
              data.marriedInfo.spouseSin !== ""
                ? await decryptText(data.marriedInfo.spouseSin, data.uid)
                : "",
          };
          // Await for all promises to resolve
          updatedDependents = await Promise.all(
            data.dependents.map(async (dependent) => {
              return {
                ...dependent,
                sin:
                  dependent.sin !== ""
                    ? await decryptText(dependent.sin, data.uid)
                    : "",
              };
            })
          );
        } else {
          setDecryptionUID(user.uid);
          sin = "*********";

          // Update the spouse SIN if the first name is not empty
          if (data.marriedInfo.spouseName !== "") {
            updatedMarriedInfo = {
              ...doc.data().marriedInfo,
              spouseSin: "*********",
            };
          } else {
            updatedMarriedInfo = {
              ...doc.data().marriedInfo,
              spouseSin: "",
            };
          }
          updatedDependents = data.dependents.map((dependent) => {
            if (dependent.name !== "") {
              return {
                ...dependent,
                sin: "*********",
              };
            } else {
              return {
                ...dependent,
                sin: "",
              };
            }
          });
        }

        setApplicationStatus(data.status);
        setApplicationNote(data?.notes ? data.notes : "");
        setApplicationAssignedTo(data.assignedTo);

        setClientUID(data.uid);
        setAccountantDocuments(data.accountantDocuments);
        setClientDocuments(data.clientDocuments);
        setCurrentOffice(data.office);
        setReferralCode(data.referralCode);
        setFirstName(data.firstName);
        setLastName(data.lastName);
        setSin(sin);
        setDateOfBirth(data.dateOfBirth);
        setPhoneNumber(data.phoneNumber);
        setEmail(data.email);
        setDateOfArrival(data.dateOfArrival);
        setAddress(data.address);
        setCity(data.city);
        setProvince(data.province);
        setPostalCode(data.postalCode);
        setDateOfAddressChange(data.dateOfAddressChange);
        setMaritalStatus(data.maritalStatus);
        setDateOfMaritalStatusChange(data.dateOfMaritalStatusChange);
        setMarriedInfo(updatedMarriedInfo);
        setDependents(updatedDependents);
        setRent(data.rent);
        setCurrentRequireReviseDocuments(data.requireReviseDocuments);
      }
    });

    return () => {
      unsubscribe();
    };
  }, []);

  const handleUpdateStatus = (
    e,
    status,
    assignedTo,
    office,
    notes,
    requireReviseDocuments
  ) => {
    e.preventDefault();
    if (role === "Admin" || role === "Employee") {
      if (
        status === applicationStatus &&
        notes === applicationNote &&
        assignedTo === applicationAssignedTo &&
        office === currentOffice &&
        requireReviseDocuments === currentRequireReviseDocuments
      ) {
        setToggleUpdateStatus(false);
        return;
      }
      setError("");

      if (role === "Admin") {
        dispatch({
          type: "SET_LOADING",
          loading: true,
        });

        const userRef = doc(
          collection(db, "users", clientUID, "taxApplications"),
          taxYear
        );

        const batch = writeBatch(db);

        batch.update(yearPersonalApplication, {
          status: status,
          assignedTo: assignedTo === "" ? "-" : assignedTo,
          office: office,
          notes: notes,
          requireReviseDocuments: requireReviseDocuments === "true",
        });

        batch.update(userRef, {
          "personal.requireReviseDocuments": requireReviseDocuments === "true",
          "personal.accountantUploaded":
            Object.keys(accountantDocuments).length > 0 ? true : false,
          "personal.status":
            status === "Completed" ? "Completed" : "In Progress",
        });

        batch
          .commit()
          .then(() => {
            setToggleUpdateStatus(false);
          })
          .catch((error) => {
            switch (error.code) {
              case "permission-denied":
                setError("User not authorized to update user document");
                break;
              case "not-found":
                setError("User document not found");
                break;
              default:
                setError("Error updating status");
            }
          })
          .finally(() => {
            dispatch({
              type: "SET_LOADING",
              loading: false,
            });
          });
      } else if (role === "Employee") {
        dispatch({
          type: "SET_LOADING",
          loading: true,
        });
        updateDoc(yearPersonalApplication, {
          status: status,
          notes: notes,
        })
          .catch((error) => {
            setError("Error updating application document");
          })
          .finally(() => {
            // Add loading state...
            dispatch({
              type: "SET_LOADING",
              loading: false,
            });
          });
      } else {
        setError("User not authorized to update application status");
      }
    } else {
      console.log("User not authorized to update application status");
    }
  };

  const handleFileUpload = (e) => {
    e.preventDefault();
    const newClientDocuments = { ...accountantDocuments };
    for (let i = 0; i < e.target.files.length; i++) {
      const file = e.target.files[i];
      const fileName = file.name;
      newClientDocuments[fileName] = file;
    }
    // Update the state after all promises have resolved
    setAccountantDocuments(newClientDocuments);
  };

  const handleFileRemove = (e, name) => {
    e.preventDefault();
    const newClientDocuments = { ...accountantDocuments };
    delete newClientDocuments[name];
    setAccountantDocuments(newClientDocuments);
  };

  const downloadFiles = async (e) => {
    e.preventDefault();
    var zip = new JSZip();

    dispatch({
      type: "SET_LOADING",
      loading: true,
    });

    await new Promise((resolve, reject) => {
      const promises = [];

      for (const person in clientDocuments) {
        for (const documentType in clientDocuments[person]) {
          for (const name in clientDocuments[person][documentType]) {
            const promise = decryptBlob(
              taxYear.toString(),
              clientUID,
              decryptionUID,
              "personalApplication",
              name
            ).then((decryptedBlob) => {
              if (person === "yourself") {
                zip
                  .folder(`${firstName} ${lastName}/${documentType}`)
                  .file(name, decryptedBlob);
              } else {
                zip
                  .folder(`${firstName} ${lastName}/${person}/${documentType}`)
                  .file(name, decryptedBlob);
              }
            });

            promises.push(promise);
          }
        }
      }

      Promise.all(promises)
        .then(() => {
          resolve();
        })
        .catch((error) => {
          reject(error);
          dispatch({
            type: "SET_LOADING",
            loading: false,
          });
        });
    });

    zip
      .generateAsync({ type: "blob" }, function updateCallback(metadata) {})
      .then(function (content) {
        const a = document.createElement("a");
        const url = URL.createObjectURL(content);
        a.href = url;
        a.download = `${firstName} ${lastName} - Personal Application.zip`;
        a.click();

        dispatch({
          type: "SET_LOADING",
          loading: false,
        });
      })
      .catch((error) => {
        console.log("Error generating zip file: ", error);
        dispatch({
          type: "SET_LOADING",
          loading: false,
        });
      });
  };

  const handleUpdate = (e) => {
    e.preventDefault();
    console.log(updatedFields);
    // if (Object.keys(updatedFields).length === 0) {
    //   setError("No fields to update");
    //   return;
    // }

    // dispatch({
    //   type: "SET_LOADING",
    //   loading: true,
    // });

    // updateDoc(yearPersonalApplication, updatedFields)
    //   .then(() => {
    //     dispatch({
    //       type: "SET_LOADING",
    //       loading: false,
    //     });
    //   })
    //   .catch((error) => {
    //     setError("Error updating application document");
    //   });
  };

  useEffect(() => {
    if (!firstName || !lastName || !email || !sin) {
      dispatch({
        type: "SET_LOADING",
        loading: true,
      });
    } else {
      dispatch({
        type: "SET_LOADING",
        loading: false,
      });
    }
  }, [email, firstName, lastName, sin]);

  return (
    <>
      <div style={{ padding: "10px" }}>
        <h2>Personal Application {taxYear}</h2>
        <form onSubmit={handleUpdate} className="form-container">
          <h2>Select closest office</h2>
          <div className="form-section">
            <div className="form-input">
              <label htmlFor="office">Office</label>
              <select
                name="office"
                id="office"
                value={
                  updatedFields.office !== undefined
                    ? updatedFields.office
                    : currentOffice
                }
                onChange={(e) =>
                  setUpdatedFields({
                    ...updatedFields,
                    office: e.target.value,
                  })
                }
                disabled={
                  role !== "Admin" &&
                  role !== "Employee" &&
                  currentRequireReviseDocuments
                    ? false
                    : true
                }
              >
                <option value="Scarborough">Scarborough</option>
                <option value="Oshawa">Oshawa</option>
              </select>
            </div>
            {currentOffice === "Oshawa" && (
              <div className="form-input">
                <label htmlFor="referralCode">Referral Code</label>
                <input
                  type="text"
                  name="referralCode"
                  id="referralCode"
                  value={
                    updatedFields.referralCode !== undefined
                      ? updatedFields.referralCode
                      : referralCode
                  }
                  disabled={
                    role !== "Admin" &&
                    role !== "Employee" &&
                    currentRequireReviseDocuments
                      ? false
                      : true
                  }
                  onChange={(e) => {
                    e.preventDefault();
                    setUpdatedFields({
                      ...updatedFields,
                      referralCode: e.target.value,
                    });
                  }}
                />
              </div>
            )}
          </div>
          <h2>Personal Information</h2>
          <div className="form-section">
            <div className="form-input">
              <label htmlFor="name">First Name</label>
              <input
                type="text"
                name="name"
                id="name"
                value={
                  updatedFields.firstName !== undefined
                    ? updatedFields.firstName
                    : firstName
                }
                disabled={
                  role !== "Admin" &&
                  role !== "Employee" &&
                  currentRequireReviseDocuments
                    ? false
                    : true
                }
                onChange={(e) =>
                  setUpdatedFields({
                    ...updatedFields,
                    firstName: e.target.value,
                  })
                }
              />
            </div>
            <div className="form-input">
              <label htmlFor="name">Last Name</label>
              <input
                type="text"
                name="name"
                id="name"
                value={
                  updatedFields.lastName !== undefined
                    ? updatedFields.lastName
                    : lastName
                }
                disabled={
                  role !== "Admin" &&
                  role !== "Employee" &&
                  currentRequireReviseDocuments
                    ? false
                    : true
                }
                onChange={(e) =>
                  setUpdatedFields({
                    ...updatedFields,
                    lastName: e.target.value,
                  })
                }
              />
            </div>
            <div className="form-input">
              <label htmlFor="dateOfBirth">Date of Birth</label>
              <input
                type="date"
                name="dateOfBirth"
                id="dateOfBirth"
                value={
                  updatedFields.dateOfBirth !== undefined
                    ? updatedFields.dateOfBirth
                    : dateOfBirth
                }
                disabled={
                  role !== "Admin" &&
                  role !== "Employee" &&
                  currentRequireReviseDocuments
                    ? false
                    : true
                }
                max={new Date().toISOString().split("T")[0]}
                onChange={(e) =>
                  setUpdatedFields({
                    ...updatedFields,
                    dateOfBirth: e.target.value,
                  })
                }
              />
            </div>
            <div className="form-input">
              <label htmlFor="phoneNumber">Phone Number</label>
              <input
                type="text"
                name="phoneNumber"
                id="phoneNumber"
                value={
                  updatedFields.phoneNumber !== undefined
                    ? updatedFields.phoneNumber
                    : phoneNumber
                }
                disabled={
                  role !== "Admin" &&
                  role !== "Employee" &&
                  currentRequireReviseDocuments
                    ? false
                    : true
                }
                onChange={(e) =>
                  setUpdatedFields({
                    ...updatedFields,
                    phoneNumber: e.target.value,
                  })
                }
              />
            </div>
            <div className="form-input">
              <label htmlFor="email">Email</label>
              <input
                type="email"
                name="email"
                id="email"
                value={
                  updatedFields.email !== undefined
                    ? updatedFields.email
                    : email
                }
                disabled={
                  role !== "Admin" &&
                  role !== "Employee" &&
                  currentRequireReviseDocuments
                    ? false
                    : true
                }
                onChange={(e) =>
                  setUpdatedFields({ ...updatedFields, email: e.target.value })
                }
              />
            </div>
            <div className="form-input">
              <label htmlFor="sin">SIN</label>
              {/* Encrypt SIN after writing */}
              <input
                type="text"
                name="sin"
                id="sin"
                value={sin}
                disabled={true}
              />
            </div>
            <div className="form-input">
              <label htmlFor="dateOfArrival">Date of Arrival in Canada</label>
              <input
                type="date"
                name="dateOfArrival"
                id="dateOfArrival"
                // How to set no date if not selected
                value={
                  updatedFields.dateOfArrival !== undefined
                    ? updatedFields.dateOfArrival
                    : dateOfArrival
                }
                disabled={
                  role !== "Admin" &&
                  role !== "Employee" &&
                  currentRequireReviseDocuments
                    ? false
                    : true
                }
                max={new Date().toISOString().split("T")[0]}
                onChange={(e) =>
                  setUpdatedFields({
                    ...updatedFields,
                    dateOfArrival: e.target.value,
                  })
                }
              />
            </div>
          </div>
          <h2>Mailing Address</h2>
          <div className="form-section">
            <div className="form-input">
              <label htmlFor="address">
                Address - Street No Street Name, PO Box, RR
              </label>
              <input
                type="text"
                name="address"
                id="address"
                value={
                  updatedFields.address !== undefined
                    ? updatedFields.address
                    : address
                }
                disabled={
                  role !== "Admin" &&
                  role !== "Employee" &&
                  currentRequireReviseDocuments
                    ? false
                    : true
                }
                onChange={(e) =>
                  setUpdatedFields({
                    ...updatedFields,
                    address: e.target.value,
                  })
                }
              />
            </div>
            <div className="form-input">
              <label htmlFor="city">City</label>
              <input
                type="text"
                name="city"
                id="city"
                value={
                  updatedFields.city !== undefined ? updatedFields.city : city
                }
                disabled={
                  role !== "Admin" &&
                  role !== "Employee" &&
                  currentRequireReviseDocuments
                    ? false
                    : true
                }
                onChange={(e) =>
                  setUpdatedFields({ ...updatedFields, city: e.target.value })
                }
              />
            </div>
            <div className="form-input">
              <label htmlFor="province">Province</label>
              <input
                type="text"
                name="province"
                id="province"
                value={
                  updatedFields.province !== undefined
                    ? updatedFields.province
                    : province
                }
                disabled={
                  role !== "Admin" &&
                  role !== "Employee" &&
                  currentRequireReviseDocuments
                    ? false
                    : true
                }
                onChange={(e) =>
                  setUpdatedFields({
                    ...updatedFields,
                    province: e.target.value,
                  })
                }
              />
            </div>
            <div className="form-input">
              <label htmlFor="postalCode">Postal Code</label>
              <input
                type="text"
                name="postalCode"
                id="postalCode"
                value={
                  updatedFields.postalCode !== undefined
                    ? updatedFields.postalCode
                    : postalCode
                }
                disabled={
                  role !== "Admin" &&
                  role !== "Employee" &&
                  currentRequireReviseDocuments
                    ? false
                    : true
                }
                onChange={(e) =>
                  setUpdatedFields({
                    ...updatedFields,
                    postalCode: e.target.value,
                  })
                }
              />
            </div>
            <div className="form-input">
              <label htmlFor="addressChange">Date of Address Change</label>
              <input
                type="date"
                name="addressChange"
                id="addressChange"
                value={
                  updatedFields.dateOfAddressChange !== undefined
                    ? updatedFields.dateOfAddressChange
                    : dateOfAddressChange
                }
                disabled={
                  role !== "Admin" &&
                  role !== "Employee" &&
                  currentRequireReviseDocuments
                    ? false
                    : true
                }
                max={new Date().toISOString().split("T")[0]}
                onChange={(e) =>
                  setUpdatedFields({
                    ...updatedFields,
                    dateOfAddressChange: e.target.value,
                  })
                }
              />
            </div>
          </div>
          <h2>Rental Information</h2>
          <div className="form-section">
            <div className="form-input">
              <label htmlFor="rent">Total amount per year</label>
              <input
                type="number"
                name="rent"
                id="rent"
                value={
                  updatedFields.rent?.amount !== undefined
                    ? updatedFields.rent.amount
                    : rent.amount
                }
                disabled={
                  role !== "Admin" &&
                  role !== "Employee" &&
                  currentRequireReviseDocuments
                    ? false
                    : true
                }
                onChange={(e) =>
                  setUpdatedFields({
                    ...updatedFields,
                    rent: {
                      ...rent,
                      amount: Number(e.target.value),
                    },
                  })
                }
              />
            </div>
            <div className="form-input">
              <label htmlFor="months">Total months to claim</label>
              <input
                type="number"
                name="months"
                id="months"
                max={12}
                min={0}
                value={
                  updatedFields.rent?.months !== undefined
                    ? updatedFields.rent.months
                    : rent.months
                }
                disabled={
                  role !== "Admin" &&
                  role !== "Employee" &&
                  currentRequireReviseDocuments
                    ? false
                    : true
                }
                onChange={(e) =>
                  setUpdatedFields({
                    ...updatedFields,
                    rent: {
                      ...rent,
                      months: Number(e.target.value),
                    },
                  })
                }
              />
            </div>
          </div>
          <h2>Marital Information</h2>
          <div className="form-section">
            <div className="form-input">
              <label htmlFor="maritalStatus">Marital Status</label>
              <select
                name="maritalStatus"
                id="maritalStatus"
                value={
                  updatedFields.maritalStatus !== undefined
                    ? updatedFields.maritalStatus
                    : maritalStatus
                }
                disabled={
                  role !== "Admin" &&
                  role !== "Employee" &&
                  currentRequireReviseDocuments
                    ? false
                    : true
                }
                onChange={(e) =>
                  setUpdatedFields({
                    ...updatedFields,
                    maritalStatus: e.target.value,
                  })
                }
              >
                <option value="Single">Single</option>
                <option value="Married">Married</option>
                <option value="Divorced">Divorced</option>
                <option value="Widowed">Widowed</option>
                <option value="Living Common-Law">Living Common Law</option>
              </select>
            </div>
            {((updatedFields.maritalStatus !== undefined &&
              updatedFields.maritalStatus !== "Single") ||
              maritalStatus !== "Single") && (
              <div className="form-input">
                <label htmlFor="dateOfMaritalStatusChange">
                  Date of Marital Status Change
                </label>
                <input
                  type="date"
                  name="dateOfMaritalStatusChange"
                  id="dateOfMaritalStatusChange"
                  value={
                    updatedFields.dateOfMaritalStatusChange !== undefined
                      ? updatedFields.dateOfMaritalStatusChange
                      : dateOfMaritalStatusChange
                  }
                  disabled={
                    role !== "Admin" &&
                    role !== "Employee" &&
                    currentRequireReviseDocuments
                      ? false
                      : true
                  }
                  max={new Date().toISOString().split("T")[0]}
                  onChange={(e) =>
                    setUpdatedFields({
                      ...updatedFields,
                      dateOfMaritalStatusChange: e.target.value,
                    })
                  }
                />
              </div>
            )}
            {((updatedFields.maritalStatus !== undefined &&
              updatedFields.maritalStatus === "Married") ||
              maritalStatus === "Married") && (
              <>
                <div className="form-input">
                  <label htmlFor="spouseName">Spouse Name</label>
                  <input
                    type="text"
                    name="spouseName"
                    id="spouseName"
                    value={
                      updatedFields.marriedInfo?.spouseName !== undefined
                        ? updatedFields.marriedInfo.spouseName
                        : marriedInfo.spouseName
                    }
                    disabled={
                      role !== "Admin" &&
                      role !== "Employee" &&
                      currentRequireReviseDocuments
                        ? false
                        : true
                    }
                    onChange={(e) =>
                      setUpdatedFields({
                        ...updatedFields,
                        marriedInfo: {
                          ...marriedInfo,
                          spouseName: e.target.value,
                        },
                      })
                    }
                  />
                </div>
                <div className="form-input">
                  <label htmlFor="spouseDateOfBirth">
                    Spouse Date of Birth
                  </label>
                  <input
                    type="date"
                    name="spouseDateOfBirth"
                    id="spouseDateOfBirth"
                    value={
                      updatedFields.marriedInfo?.spouseDateOfBirth !== undefined
                        ? updatedFields.marriedInfo.spouseDateOfBirth
                        : marriedInfo.spouseDateOfBirth
                    }
                    disabled={
                      role !== "Admin" &&
                      role !== "Employee" &&
                      currentRequireReviseDocuments
                        ? false
                        : true
                    }
                    max={new Date().toISOString().split("T")[0]}
                    onChange={(e) =>
                      setUpdatedFields({
                        ...updatedFields,
                        marriedInfo: {
                          ...marriedInfo,
                          spouseDateOfBirth: e.target.value,
                        },
                      })
                    }
                  />
                </div>
                <div className="form-input">
                  <label htmlFor="spouseEmail">Spouse Email</label>
                  <input
                    type="email"
                    name="spouseEmail"
                    id="spouseEmail"
                    value={
                      updatedFields.marriedInfo?.spouseEmail !== undefined
                        ? updatedFields.marriedInfo.spouseEmail
                        : marriedInfo.spouseEmail
                    }
                    disabled={
                      role !== "Admin" &&
                      role !== "Employee" &&
                      currentRequireReviseDocuments
                        ? false
                        : true
                    }
                    onChange={(e) =>
                      setUpdatedFields({
                        ...updatedFields,
                        marriedInfo: {
                          ...marriedInfo,
                          spouseEmail: e.target.value,
                        },
                      })
                    }
                  />
                </div>
                <div className="form-input">
                  <label htmlFor="spousePhoneNumber">Spouse Phone Number</label>
                  <input
                    type="text"
                    name="spousePhoneNumber"
                    id="spousePhoneNumber"
                    value={
                      updatedFields.marriedInfo?.spousePhoneNumber !== undefined
                        ? updatedFields.marriedInfo.spousePhoneNumber
                        : marriedInfo.spousePhoneNumber
                    }
                    disabled={
                      role !== "Admin" &&
                      role !== "Employee" &&
                      currentRequireReviseDocuments
                        ? false
                        : true
                    }
                    onChange={(e) =>
                      setUpdatedFields({
                        ...updatedFields,
                        marriedInfo: {
                          ...marriedInfo,
                          spousePhoneNumber: e.target.value,
                        },
                      })
                    }
                  />
                </div>
                <div className="form-input">
                  <label htmlFor="spouseSin">Spouse SIN</label>
                  <input
                    type="text"
                    name="spouseSin"
                    id="spouseSin"
                    value={
                      updatedFields.marriedInfo?.spouseSin !== undefined
                        ? updatedFields.marriedInfo.spouseSin
                        : marriedInfo.spouseSin
                    }
                    disabled={marriedInfo.spouseName === "" ? false : true}
                    onChange={(e) => {
                      setUpdatedFields({
                        ...updatedFields,
                        marriedInfo: {
                          ...marriedInfo,
                          spouseSin: e.target.value,
                        },
                      });
                    }}
                  />
                </div>
              </>
            )}
          </div>
          <div className="form-header">
            <h2>Dependents</h2>
            <Button
              type="button"
              disabled={
                role !== "Admin" &&
                role !== "Employee" &&
                currentRequireReviseDocuments
                  ? false
                  : true
              }
              onClick={(e) => {
                e.preventDefault();
                setDependents([
                  ...dependents,
                  {
                    name: "",
                    dateOfBirth: "",
                    sin: "",
                  },
                ]);
              }}
            >
              <span id="additional_buttons">
                <img alt="add" src="/images/dashboard/add.png" id="addButton" />
                <p>Add Dependent</p>
              </span>
            </Button>
          </div>
          <div className="form-section">
            <div className="form-input">
              {dependents.map((dependent, index) => (
                <div key={index} className="form-section">
                  <div className="form-input">
                    <label htmlFor="name">Name</label>
                    <input
                      type="text"
                      name="name"
                      id="name"
                      value={
                        updatedFields.dependents?.[index]?.name !== undefined
                          ? updatedFields.dependents[index].name
                          : dependent.name
                      }
                      disabled={
                        role !== "Admin" &&
                        role !== "Employee" &&
                        currentRequireReviseDocuments
                          ? false
                          : true
                      }
                      onChange={(e) => {
                        const newDependents = [...dependents];
                        newDependents[index].name = e.target.value;
                        setUpdatedFields({
                          ...updatedFields,
                          dependents: newDependents,
                        });
                      }}
                    />
                  </div>
                  <div className="form-input">
                    <label htmlFor="dateOfBirth">Date of Birth</label>
                    <input
                      type="date"
                      name="dateOfBirth"
                      id="dateOfBirth"
                      value={
                        updatedFields.dependents?.[index]?.dateOfBirth !==
                        undefined
                          ? updatedFields.dependents[index].dateOfBirth
                          : dependent.dateOfBirth
                      }
                      disabled={
                        role !== "Admin" &&
                        role !== "Employee" &&
                        currentRequireReviseDocuments
                          ? false
                          : true
                      }
                      max={new Date().toISOString().split("T")[0]}
                      onChange={(e) => {
                        const newDependents = [...dependents];
                        newDependents[index].dateOfBirth = e.target.value;
                        setUpdatedFields({
                          ...updatedFields,
                          dependents: newDependents,
                        });
                      }}
                    />
                  </div>
                  <div className="form-input">
                    <label htmlFor="sin">SIN</label>
                    <input
                      type="text"
                      name="sin"
                      id="sin"
                      value={updatedFields?.dependent?.sin}
                      defaultValue={dependent.sin}
                      disabled={dependent.name === "" ? false : true}
                      onChange={(e) => {
                        setUpdatedFields({
                          ...updatedFields,
                          dependent: {
                            ...dependent,
                            sin: e.target.value,
                          },
                        });
                      }}
                    />
                  </div>
                </div>
              ))}
            </div>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <h2>Tax Documents</h2>
            <Button variant="contained" onClick={downloadFiles}>
              Download All Files
            </Button>
          </div>
          {Object.keys(clientDocuments).length > 0 ? (
            <>
              <div className="form-section">
                {documentTypes.map((documentType, index) => (
                  <FileInput
                    key={index}
                    label={documentType}
                    section="yourself"
                    documentType={documentType}
                    status={"viewData"}
                    handleFileUpload={handleFileUpload}
                    uploadedFiles={clientDocuments.yourself[documentType]}
                    handleFileRemove={handleFileRemove}
                  />
                ))}
              </div>
              {maritalStatus === "Married" && (
                <>
                  <h2>Spouse's Documents</h2>
                  <div className="form-section">
                    {documentTypes.map((documentType, index) => (
                      <FileInput
                        key={index}
                        label={documentType}
                        section="spouse"
                        documentType={documentType}
                        status={"viewData"}
                        handleFileUpload={handleFileUpload}
                        uploadedFiles={clientDocuments.spouse[documentType]}
                        handleFileRemove={handleFileRemove}
                      />
                    ))}
                  </div>
                </>
              )}
              {dependents.length > 0 && (
                <>
                  <h2>Dependents Documents</h2>
                  {dependents.map(
                    (dependent, index) =>
                      dependent.name !== "" && (
                        <div key={index}>
                          <h3 style={{ marginBottom: "10px" }}>
                            {dependent.name}
                          </h3>
                          <div className="form-section">
                            {documentTypes.map((documentType, index) => (
                              <FileInput
                                key={index}
                                label={documentType}
                                section="dependents"
                                documentType={documentType}
                                status={"viewData"}
                                handleFileUpload={handleFileUpload}
                                uploadedFiles={
                                  clientDocuments.dependents[documentType]
                                }
                                handleFileRemove={handleFileRemove}
                              />
                            ))}
                          </div>
                        </div>
                      )
                  )}
                </>
              )}
            </>
          ) : (
            <p>No documents uploaded</p>
          )}
          {role === "Admin" && (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                gap: "10px !important",
              }}
            >
              {/* <div className="form-header">
                <h2>Accountant Upload</h2>
                <label
                  htmlFor="accountant-uploaded"
                  className="custom-file-button"
                >
                  Upload
                </label>
                <input
                  style={{ display: "none" }}
                  name="accountant-uploaded"
                  id="accountant-uploaded"
                  type="file"
                  accept="image/*,.pdf"
                  multiple
                  disabled={
                    role !== "Admin" || role !== "Employee" ? false : true
                  }
                  onChange={handleFileUpload}
                ></input>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "5px !important",
                }}
              >
                {Object.keys(accountantDocuments).map((fileName, index) => (
                  <div
                    key={index}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                  >
                    <p
                      style={{
                        maxWidth: "230px",
                        textOverflow: "ellipsis",
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                      }}
                    >
                      {fileName}
                    </p>
                    <IconButton
                      color="error"
                      onClick={(e) => handleFileRemove(e, fileName)}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </div>
                ))}
              </div> */}
              <Button
                variant="contained"
                onClick={(e) => {
                  setToggleUpdateStatus(true);
                }}
              >
                Update Status
              </Button>
            </div>
          )}
          {currentRequireReviseDocuments && clientUID === user.uid && (
            <Button
              variant="contained"
              onClick={(e) => {
                console.log("Update Document");
              }}
            >
              Update Document
            </Button>
          )}
        </form>
      </div>
      {toggleUpdateStatus && (
        <UpdateStatus
          setTogglePopup={setToggleUpdateStatus}
          currentStatus={applicationStatus}
          currentNote={applicationNote}
          assignedTo={applicationAssignedTo}
          office={currentOffice}
          requireReviseDocuments={currentRequireReviseDocuments}
          handleSubmit={handleUpdateStatus}
          error={error}
        />
      )}
    </>
  );
}

export default ViewPersonalApplication;
