import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import "ag-grid-community/styles/ag-theme-quartz.css";
import React, { useCallback, useEffect, useRef, useState } from "react";
import db from "../../firebase";
import {
  collection,
  doc,
  onSnapshot,
  query,
  updateDoc,
  where,
} from "firebase/firestore";
import { useStateValue } from "../../StateProvider";
import { AgGridReact } from "ag-grid-react";
import { useNavigate } from "react-router-dom";
import { Button } from "@mui/material";

import "./Dashboard.scss";

function AdminPersonalApplications() {
  const [{ user, role }, dispatch] = useStateValue();
  const history = useNavigate();
  const [taxYear, setTaxYear] = useState(Number(new Date().getFullYear() - 1));
  const [allPersonalApplications, setAllPersonalApplications] = useState([]);
  const [assignedToOptions, setAssignedToOptions] = useState({});
  const [rowData, setRowData] = useState([]);
  const [currentFilterText, setCurrentFilterText] = useState("");

  const gridRef = useRef(null);
  const personalApplicationRef = collection(
    db,
    "applications",
    "personal",
    taxYear.toString()
  );

  const onCellClicked = (params) => {
    if (params?.data?.id) {
      history(`/personal-applications/view/${taxYear}/${params.data.id}`);
    }
  };
  const onGridReady = useCallback((params) => {
    if (window.innerWidth >= 1170) {
      sizeToFit();
    }
  }, []);
  const sizeToFit = useCallback(() => {
    gridRef.current.api.sizeColumnsToFit({
      defaultMinWidth: 200,
    });
  }, []);
  const assignedToFormatter = (params) => {
    if (params.value === "") {
      return "-";
    }
    return assignedToOptions[params.value];
  };

  const onCellValueChanged = useCallback((params) => {
    if (params.oldValue !== params.newValue) {
      const applicationRef = doc(personalApplicationRef, params.data.id);
      switch (params.column.colId) {
        case "office":
          updateDoc(applicationRef, {
            office: params.newValue,
          });
          break;
        case "status":
          updateDoc(applicationRef, {
            status: params.newValue,
          });
          break;
        case "assignedTo":
          updateDoc(applicationRef, {
            assignedTo: params.newValue,
          });
          break;
        default:
          break;
      }
    }
  }, []);

  const columns = [
    {
      field: "id",
      headerName: "ID",
      sortable: true,
      hide: true,
      wrapText: true,
      onCellClicked: onCellClicked,
    },
    {
      field: "firstName",
      headerName: "First Name",
      sortable: true,
      filter: true,
      onCellClicked: onCellClicked,
    },
    {
      field: "lastName",
      headerName: "Last Name",
      sortable: true,
      filter: true,
      onCellClicked: onCellClicked,
    },
    {
      field: "email",
      headerName: "Email Address",
      sortable: true,
      wrapText: true,
      filter: true,
      onCellClicked: onCellClicked,
    },
    {
      field: "office",
      headerName: "Office",
      cellEditor: "agSelectCellEditor",
      cellEditorParams: {
        values: ["Oshawa", "Scarborough"],
        valueListMaxHeight: 200,
        valueListMaxWidth: 150,
      },
      filter: true,
      sortable: true,
      editable: true,
    },
    {
      field: "status",
      headerName: "Status",
      cellEditor: "agSelectCellEditor",
      cellEditorParams: {
        values: ["To Do", "In Progress", "Blocked", "Completed"],
        valueListMaxHeight: 200,
        valueListMaxWidth: 150,
      },
      filter: true,
      sortable: true,
      editable: true,
    },
    {
      field: "assignedTo",
      headerName: "Assigned To",
      cellEditor: "agSelectCellEditor",
      cellEditorParams: {
        values: ["-", ...Object.keys(assignedToOptions)],
        valueListMaxHeight: 200,
        valueListMaxWidth: 150,
      },
      valueFormatter: assignedToFormatter,
      filter: true,
      sortable: true,
      editable: true,
    },

    {
      field: "submittedOn",
      headerName: "Submitted On",
      filter: true,
      sortable: true,
      onCellClicked: onCellClicked,
    },
  ];

  useEffect(() => {
    const employeeRef = query(
      collection(db, "users"),
      where("role", "in", ["Admin", "Employee"])
    );
    const fetchEmployees = onSnapshot(employeeRef, (snapshot) => {
      setAssignedToOptions({});
      snapshot.docs.map((doc) =>
        setAssignedToOptions((prev) => ({
          ...prev,
          [doc.id]: `${doc.data().firstName} ${doc.data().lastName}`,
        }))
      );
    });

    const fetchPersonalApplications = onSnapshot(
      personalApplicationRef,
      (snapshot) => {
        setAllPersonalApplications(
          snapshot.docs
            .map((doc) => ({
              id: doc.id,
              data: doc.data(),
            }))
            .sort(
              (a, b) => b.data.submittedOn.seconds - a.data.submittedOn.seconds
            )
        );
      }
    );

    return () => {
      fetchPersonalApplications();
      fetchEmployees();
    };
  }, [taxYear]);

  useEffect(() => {
    setRowData([]);
    for (let application of allPersonalApplications) {
      let {
        firstName,
        lastName,
        email,
        office,
        status,
        assignedTo,
        submittedOn,
      } = application.data;

      const date = new Date(submittedOn?.seconds * 1000);
      const formattedDate = date.toLocaleDateString("en-US", {
        year: "numeric",
        month: "long",
        day: "numeric",
      });

      if (formattedDate !== "Invalid Date") {
        submittedOn = formattedDate;
      } else {
        submittedOn = "N/A";
      }

      if (assignedTo === "") {
        assignedTo = "-";
      }

      setRowData((prev) => [
        ...prev,
        {
          id: application.id,
          firstName,
          lastName,
          email,
          office,
          status,
          assignedTo,
          submittedOn,
        },
      ]);
    }
  }, [allPersonalApplications]);

  const handleFilter = (e) => {
    e.preventDefault();
    const office = document.getElementById("office").value;
    const status = document.getElementById("status").value;
    const assignedTo = document.getElementById("assignedTo").value;

    gridRef.current.api.setGridOption(
      "quickFilterText",
      `${office} ${status} ${assignedTo}`
    );

    setCurrentFilterText(`${office} ${status} ${assignedTo}`);
  };

  const clearFilter = (e) => {
    e.preventDefault();
    gridRef.current.api.setGridOption("quickFilterText", "");

    setCurrentFilterText("");
  };

  useEffect(() => {
    if (role !== "Admin") {
      history("/");
    }
    window.scrollTo(0, 0);
    document.title = "Personal Applications | Patel Taxation and Accounting";
  }, []);

  return (
    <div style={{ padding: "10px" }}>
      <form className="filter-container">
        <div className="filter-action-container">
          <div className="filter-item">
            <label htmlFor="year">Year</label>
            <input
              type="number"
              id="year"
              name="year"
              defaultValue={taxYear}
              min="2018"
              max={taxYear}
              onChange={(e) => setTaxYear(e.target.value)}
            />
          </div>
          <div className="filter-item">
            <label htmlFor="office">Office</label>
            <select name="office" id="office">
              <option value="">All</option>
              <option value="Oshawa">Oshawa</option>
              <option value="Scarborough">Scarborough</option>
            </select>
          </div>
          <div className="filter-item">
            <label htmlFor="status">Status</label>
            <select name="status" id="status">
              <option value="">All</option>
              <option value="To Do">To Do</option>
              <option value="In Progress">In Progress</option>
              <option value="Blocked">Blocked</option>
              <option value="Completed">Completed</option>
            </select>
          </div>
          <div className="filter-item">
            <label htmlFor="assignedTo">Assigned To</label>
            <select name="assignedTo" id="assignedTo">
              <option value="">All</option>
              {Object.entries(assignedToOptions).map(([key, value]) => (
                <option key={key} value={key}>
                  {value}
                </option>
              ))}
            </select>
          </div>
        </div>
        <div className="button_container">
          <Button
            variant="contained"
            className="actionBtn"
            type="submit"
            onClick={handleFilter}
          >
            Filter
          </Button>
          <Button
            variant="outlined"
            className="actionBtn"
            color="error"
            type="submit"
            onClick={clearFilter}
            // if ag-grid has some filter applied, clear it
            disabled={currentFilterText === ""}
          >
            Clear Filter
          </Button>
        </div>
      </form>
      <div style={{ width: "100%", height: "80vh", marginTop: "20px" }}>
        <div className="ag-theme-quartz" style={{ height: "100%" }}>
          <AgGridReact
            ref={gridRef}
            rowData={rowData}
            columnDefs={columns}
            onGridReady={onGridReady}
            onGridSizeChanged={sizeToFit}
            onCellValueChanged={onCellValueChanged}
            pagination={true}
            animateRows={true}
            cacheQuickFilter={true}
            suppressScrollOnNewData={true}
            paginationPageSize={20}
          ></AgGridReact>
        </div>
      </div>
    </div>
  );
}

export default AdminPersonalApplications;

// function CustomAssignedTo(params) {
//   const { value, data, api } = params;

//   useEffect(() => {
//     console.log("Params: ", params);
//   }, []);

//   // const handleChange = (e) => {
//   //   api.stopEditing();
//   //   api.setFocusedCell(data.rowIndex, "assignedTo");
//   //   api.startEditingCell({
//   //     rowIndex: data.rowIndex,
//   //     colKey: "assignedTo",
//   //   });
//   //   api.setPopupParent(document.body);
//   // };
//   return (
//     <div>
//       {value}
//       {/* <select
//         value={value}
//         onChange={handleChange}
//         style={{ width: "100%", height: "100%" }}
//       >
//         {assignedToOptions.map((value, index) => (
//           <option key={index} value={value}>
//             {value}
//           </option>
//         ))}
//       </select> */}
//     </div>
//   );
// }
