import React from "react";
import "./OrgInfo.scss";

function OrgInfo({ Size = "small", Logo, InfoMessage, Title = "" }) {
  return (
    <div className="org-info">
      <Logo fontSize={Size} />
      <div className="org-info-description">
        {Title && <h4>{Title}</h4>}
        <InfoMessage />
      </div>
    </div>
  );
}

export default OrgInfo;
