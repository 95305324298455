import { Alert } from "@mui/material";
import React, { useState } from "react";
import "react-phone-number-input/style.css";
import Input from "react-phone-number-input";
import { isValidPhoneNumber } from "react-phone-number-input";

function RequiredInput({
  id = null,
  inputType,
  currValue,
  type,
  placeholder,
  onChangeVariable = null,
  pattern,
  errorMessage,
  required = false,
  min = 0,
  max = 0,
}) {
  const [focused, setFocused] = useState(false);

  const handleFocus = (e) => {
    if (currValue !== "") {
      setFocused(true);
    }
  };

  return (
    <>
      {inputType === "input" && (
        <input
          id={id}
          type={type}
          value={currValue}
          placeholder={placeholder}
          onChange={(e) => onChangeVariable(e.target?.value)}
          onBlur={handleFocus}
          focused={focused.toString()}
          pattern={pattern}
          required={required}
        />
      )}
      {inputType === "amount" && (
        <div className="input-group">
          <span className="dollarSign">$</span>
          <input
            id={id}
            type={type}
            value={currValue}
            min={0}
            placeholder={placeholder}
            onChange={(e) => {
              onChangeVariable(e.target.value);
            }}
            onBlur={handleFocus}
            focused={focused.toString()}
            pattern={pattern}
            required={required}
          />
        </div>
      )}
      {inputType === "textarea" && (
        <textarea
          id={id}
          rows="6"
          cols="50"
          placeholder={placeholder}
          spellCheck="true"
          maxLength="900"
          onBlur={handleFocus}
          focused={focused.toString()}
          value={currValue}
          onChange={(e) => onChangeVariable(e.target?.value)}
          required={required}
        />
      )}
      {inputType === "date" && (
        <input
          id={id}
          type={type}
          value={currValue}
          onBlur={handleFocus}
          max={new Date().toISOString().split("T")[0]}
          focused={focused.toString()}
          onChange={(e) => onChangeVariable(e.target.value)}
          required={required}
        />
      )}
      {inputType === "phoneNumber" && (
        <Input
          country="CA"
          defaultCountry="CA"
          onBlur={handleFocus}
          value={currValue}
          onChange={onChangeVariable}
        />
      )}
      {inputType === "number" && (
        <input
          type={type}
          value={currValue}
          min={min}
          max={max !== 0 ? max : undefined}
          onChange={(e) => onChangeVariable(parseInt(e.target?.value))}
          onBlur={handleFocus}
          focused={focused.toString()}
          placeholder={placeholder}
          pattern={pattern}
          required={required}
        />
      )}

      <Alert severity="error" className="errorMessage" id="orderError">
        {errorMessage}
      </Alert>
    </>
  );
}

export default RequiredInput;
