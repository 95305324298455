import { getBlob, ref } from "firebase/storage";

const CryptoJS = require("crypto-js");
const { auth, storage } = require("../firebase");

const encryptText = (text) => {
  const secreteKey = process.env.REACT_APP_ENCRYPTION_KEY.replace(
    "uid",
    auth.currentUser.uid
  );
  return CryptoJS.AES.encrypt(text, secreteKey).toString();
};

const decryptText = (text, uid = "") => {
  return new Promise((resolve, reject) => {
    const secreteKey = process.env.REACT_APP_ENCRYPTION_KEY.replace("uid", uid);
    const bytes = CryptoJS.AES.decrypt(text, secreteKey);
    const decryptedText = bytes.toString(CryptoJS.enc.Utf8);

    if (decryptedText === "") {
      resolve("");
    }
    resolve(decryptedText);
  });
};

const encryptBlob = async (file, uid) => {
  return new Promise((resolve, reject) => {
    try {
      const reader = new FileReader();
      reader.onload = (e) => {
        const fileData = e.target.result;
        const wordArray = CryptoJS.lib.WordArray.create(fileData);

        const encryptedFile = CryptoJS.AES.encrypt(
          wordArray,
          process.env.REACT_APP_ENCRYPTION_KEY.replace("uid", uid)
        ).toString();

        // Convert the encrypted WordArray back to a blob
        const encryptedBlob = new Blob([encryptedFile], {
          type: file.type,
        });

        resolve(encryptedBlob);
      };
      reader.readAsArrayBuffer(file);
    } catch (e) {
      reject("Error while encrypting the file");
    }
  });
};

const decryptBlob = (year, uid, decryptionUID, taxType, name) => {
  return new Promise(async (resolve, reject) => {
    const storageRef = ref(storage, `${year}/${uid}/${taxType}/${name}`);
    try {
      const blob = await getBlob(storageRef);
      const reader = new FileReader();
      reader.onload = (e) => {
        const encryptedFile = e.target.result;

        // Decrypt the file
        const decryptedWordArray = CryptoJS.AES.decrypt(
          encryptedFile,
          process.env.REACT_APP_ENCRYPTION_KEY.replace("uid", decryptionUID)
        );

        // Convert the decrypted WordArray to a Uint8Array
        const decryptedUint8Array = new Uint8Array(
          decryptedWordArray.words.length * 4
        );

        for (let i = 0; i < decryptedWordArray.words.length; i++) {
          decryptedUint8Array[i * 4] =
            (decryptedWordArray.words[i] >> 24) & 0xff;
          decryptedUint8Array[i * 4 + 1] =
            (decryptedWordArray.words[i] >> 16) & 0xff;
          decryptedUint8Array[i * 4 + 2] =
            (decryptedWordArray.words[i] >> 8) & 0xff;
          decryptedUint8Array[i * 4 + 3] = decryptedWordArray.words[i] & 0xff;
        }

        // Create a Blob from the ArrayBuffer
        const decryptedBlob = new Blob([decryptedUint8Array], {
          type: blob.type,
        });

        resolve(decryptedBlob);
      };
      reader.readAsText(blob);
    } catch (e) {
      if (e.code === "storage/object-not-found") {
        reject("File not found");
      } else {
        reject(`Error while decrypting file-${name}`, e);
      }
    }
  });
};

export { encryptText, decryptText, encryptBlob, decryptBlob };
