export const initialState = {
  user: null,
  displayName: "",
  role: "Regular",
  emailVerified: false,
  referralCode: "",
  initialized: false,
  loading: false,
};

export const actionTypes = {
  SET_LOADING: "SET_LOADING",
  SET_LOGIN_USER: "SET_LOGIN_USER",
  SET_USER: "SET_USER",
  SET_ROLE: "SET_ROLE",
  SET_EMAIL_VERIFIED: "SET_EMAIL_VERIFIED",
  SET_CLEANUP: "SET_CLEANUP",
};

const reducer = (state, action) => {
  switch (action.type) {
    case actionTypes.SET_LOADING:
      return {
        ...state,
        loading: action.loading,
      };
    case actionTypes.SET_CLEANUP:
      return {
        ...initialState,
      };
    case actionTypes.SET_LOGIN_USER:
      return {
        ...state,
        user: action.user,
        displayName: action.user.displayName,
      };
    case actionTypes.SET_USER:
      return {
        ...state,
        user: action.user,
        role: action.role,
        emailVerified: action.emailVerified,
        referralCode: action.referralCode,
        initialized: action.initialized,
      };
    case actionTypes.SET_EMAIL_VERIFIED:
      return {
        ...state,
        emailVerified: action.emailVerified,
      };
    default:
      return state;
  }
};

export default reducer;
