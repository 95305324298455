import { useEffect, useState } from "react";
import "./App.scss";
import Footer from "./Components/Footer/Footer";
import Navbar from "./Components/Navbar/Navbar";
import Home from "./Pages/Home/Home";
import SignIn from "./Pages/Sign In/SignIn";
import db, { auth } from "./firebase";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import { useStateValue } from "./StateProvider";
import SignUp from "./Pages/Sign Up/SignUp";
import {
  DocumentReference,
  collection,
  doc,
  getDoc,
  onSnapshot,
  setDoc,
  updateDoc,
  writeBatch,
} from "firebase/firestore";
import DashboardIndex from "./Pages/Dashboard/DashboardIndex";
import PersonalApplication from "./Pages/Dashboard/PersonalApplication";
import Referrals from "./Pages/Dashboard/Referrals/Referrals";
import { RecaptchaVerifier, sendEmailVerification } from "firebase/auth";
import AdminPersonalApplications from "./Pages/Dashboard/AdminPersonalApplications";
import ViewPersonalApplication from "./Pages/Application/ViewPersonalApplication";
import RoleManagement from "./Pages/Dashboard/RoleManagement/RoleManagement";
import SpinnerSVG from "./spinner.svg";

function App() {
  const [{ user, role, initialized, displayName, loading }, dispatch] =
    useStateValue();

  const randomReferralCode = () => {
    const chars =
      "0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ"; // Add more characters
    let result = "";
    for (let i = 6; i > 0; --i)
      result += chars[Math.floor(Math.random() * chars.length)];
    return result;
  };

  useEffect(() => {
    auth.onAuthStateChanged((authUser) => {
      dispatch({
        type: "SET_LOADING",
        loading: true,
      });
      if (user && displayName && user?.uid === authUser?.uid) {
        // When we sign up or sign in!
        const name = displayName.split(" ");
        const userCollectionRef = doc(collection(db, "users"), user.uid);

        const updateUserData = async () => {
          // Does the doc exist?
          try {
            const docSnap = await getDoc(userCollectionRef);
            if (!docSnap.exists()) {
              let referralCode = randomReferralCode();
              await setDoc(userCollectionRef, {
                firstName: name[0],
                lastName: name[1],
                email: user.email,
                uid: user.uid,
                emailVerified: user.emailVerified,
                filedPersonalApplication: [],
                filedIncorporationApplication: [],
                referralCode: referralCode,
              });
            } else {
              if (
                docSnap.data().emailVerified === false &&
                user.emailVerified
              ) {
                await updateDoc(userCollectionRef, {
                  emailVerified: user.emailVerified,
                });
                await setDoc(
                  doc(
                    collection(db, "referralCodes"),
                    docSnap.data().referralCode
                  ),
                  {
                    firstName: name[0],
                    lastName: name[1],
                    email: user.email,
                    // uid: user.uid,
                  }
                );
              }
            }
          } catch (e) {
            console.log("Error getting document:", e);
          }
        };

        updateUserData();
      } else if (!user && authUser) {
        // user is logged in and coming back
        dispatch({
          type: "SET_LOGIN_USER",
          user: authUser,
        });
      } else {
        // the user is logged out
        dispatch({
          type: "SET_CLEANUP",
        });
      }
    });
  }, [dispatch, displayName, user]);

  useEffect(() => {
    try {
      if (user) {
        const userCollectionRef = doc(collection(db, "users"), user.uid);
        const unsub = onSnapshot(userCollectionRef, (doc) => {
          dispatch({
            type: "SET_USER",
            user: user,
            emailVerified: doc.data()?.emailVerified,
            role:
              doc.data()?.role !== undefined && doc.data()?.role !== null
                ? doc.data().role
                : role,
            referralCode: doc.data()?.referralCode,
            initialized: doc.data()?.emailVerified !== undefined ? true : false,
          });

          dispatch({
            type: "SET_LOADING",
            loading: false,
          });
        });
        return () => unsub();
      }
    } catch (e) {
      console.log("Error while setting up user listener", e);
    }
  }, [user]);

  return (
    <div className="App">
      {loading && (
        <div className="loading_container">
          <img src={SpinnerSVG} alt="Loading..." id="spinner" />
        </div>
      )}
      {user && initialized ? (
        <Router>
          <Routes>
            {role === "Admin" && (
              <>
                <Route
                  path="/admin/roles"
                  element={
                    !user || role !== "Admin" ? (
                      <Navigate to="/dashboard" />
                    ) : (
                      <div className="pageContainer">
                        <Navbar />
                        <RoleManagement />
                        <Footer />
                      </div>
                    )
                  }
                />
                <Route
                  path="/admin/referrals"
                  element={
                    !user || role !== "Admin" ? (
                      <Navigate to="/dashboard" />
                    ) : (
                      <div className="pageContainer">
                        <Navbar />
                        <Referrals />
                        <Footer />
                      </div>
                    )
                  }
                />
                <Route
                  path="/admin/personal-applications"
                  element={
                    !user || role !== "Admin" ? (
                      <Navigate to="/dashboard" />
                    ) : (
                      <div className="pageContainer">
                        <Navbar />
                        <AdminPersonalApplications />
                        <Footer />
                      </div>
                    )
                  }
                />
              </>
            )}
            <Route
              path="/personal-application/:year"
              element={
                <div className="pageContainer">
                  <Navbar />
                  <PersonalApplication />
                  <Footer />
                </div>
              }
            />
            <Route
              path="/personal-applications/view/:year/:id"
              element={
                <div className="pageContainer">
                  <Navbar />
                  <ViewPersonalApplication />
                  <Footer />
                </div>
              }
            />
            <Route
              path="/dashboard"
              element={
                <div className="pageContainer">
                  <Navbar />
                  <DashboardIndex />
                  <Footer />
                </div>
              }
            />
            <Route path="*" element={<Navigate to="/dashboard" />} />
          </Routes>
        </Router>
      ) : (
        <Router>
          <Routes>
            <Route
              path="/signup"
              element={
                user ? (
                  <Navigate to="/" />
                ) : (
                  <div className="pageContainer">
                    <SignUp />
                  </div>
                )
              }
            />
            <Route
              path="/login"
              element={
                user ? (
                  <Navigate to="/" />
                ) : (
                  <div className="pageContainer">
                    <SignIn />
                  </div>
                )
              }
            />
            <Route
              path="/"
              element={
                <div className="pageContainer">
                  <Navbar />
                  <Home />
                  <Footer />
                </div>
              }
            />
            <Route path="*" element={<Navigate to="/" />} />
          </Routes>
        </Router>
      )}
    </div>
  );
}

export default App;
