import React, { useEffect, useState } from "react";
import { useStateValue } from "../../StateProvider";
import db, { auth, optOutOfMFA } from "../../firebase";
import { collection, doc, getDoc } from "firebase/firestore";
import { Button, IconButton } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { multiFactor } from "firebase/auth";
import MFAWorkflow from "../../Components/Popup/MFAWorkflow";
import Reauthenticate from "../../Components/Popup/Reauthenticate";
import CopyAllRoundedIcon from "@mui/icons-material/CopyAllRounded";
import DoneAllRoundedIcon from "@mui/icons-material/DoneAllRounded";
import ReferralSvg from "./../../Referral.svg";
import TwoFactorAuthSvg from "./../../twoFactorAuthentication.svg";
import "./Dashboard.scss";
import EmailVerificationReq from "../../Components/Popup/EmailVerificationReq";
import { actionTypes } from "../../Reducer";

function RegularDashboard() {
  const currentTaxYear = Number(new Date().getFullYear() - 1); // convert to number
  const navigate = useNavigate();
  const [{ user, referralCode, emailVerified }, dispatch] = useStateValue();
  const userCollectionRef = doc(collection(db, "users"), user?.uid);

  const [filedPersonalApplication, setFiledPersonalApplication] = useState([]);
  const [personalReviseApplication, setPersonalReviseApplication] =
    useState(false);
  const [personalAccountantUpload, setPersonalAccountantUpload] =
    useState(false);

  const [filedIncorporationApplication, setFiledIncorporationApplication] =
    useState([]);
  const [mfaPopup, setMfaPopup] = useState(false);
  const [selectedTaxYear, setSelectedTaxYear] = useState(currentTaxYear);
  const [reauthenticatePopup, setReauthenticatePopup] = useState(false);
  const [copiedReferralCode, setCopiedReferralCode] = useState(false);
  const [emailVerificationPopup, setEmailVerificationPopup] = useState(false);
  const [requiredVerifiedEmail, setRequiredVerifiedEmail] = useState(false);

  const [applicationStatus, setApplicationStatus] = useState("-");
  const [applicationSubmittedOn, setApplicationSubmittedOn] = useState("-");

  useEffect(() => {
    const applicationRef = doc(
      collection(db, "users", user.uid, "taxApplications"),
      selectedTaxYear.toString()
    );
    const getApplicationData = async () => {
      await getDoc(applicationRef).then((doc) => {
        if (doc.exists()) {
          setApplicationStatus(doc.data().personal.status);
          if (doc.data().personal.submittedOn) {
            const date = new Date(
              doc.data().personal.submittedOn?.seconds * 1000
            );
            const formattedDate = date.toLocaleDateString("en-US", {
              year: "numeric",
              month: "long",
              day: "numeric",
            });

            setApplicationSubmittedOn(
              doc.data().personal.submittedOn ? formattedDate : "-"
            );
          } else {
            setApplicationSubmittedOn("-");
          }
        }
      });
    };

    const fetchFiledApplications = async () => {
      await getDoc(userCollectionRef).then((doc) => {
        setFiledPersonalApplication(doc.data().filedPersonalApplication);
        setFiledIncorporationApplication(
          doc.data().filedIncorporationApplication
        );
      });
    };
    fetchFiledApplications();
    getApplicationData();
  }, []);

  const startApplication = async (e, taxType) => {
    e.preventDefault();
    if (taxType === "personal") {
      navigate(`/personal-application/${currentTaxYear}`);
    } else if (taxType === "incorporation") {
      navigate(`/incorporation-application/${currentTaxYear}`);
    }
  };

  const viewApplication = async (e, taxType) => {
    e.preventDefault();
    if (taxType === "personal") {
      const applicationRef = doc(
        collection(db, "users", user.uid, "taxApplications"),
        selectedTaxYear.toString()
      );
      getDoc(applicationRef).then((doc) => {
        navigate(
          `/personal-applications/view/${selectedTaxYear}/${
            doc.data().personal.applicationId
          }`
        );
      });
    } else if (taxType === "incorporation") {
      navigate(`/view/incorporation-applications/${selectedTaxYear}`);
    }
  };

  const optOutListener = async (e) => {
    e.preventDefault();
    dispatch({
      type: actionTypes.SET_LOADING,
      loading: true,
    });
    try {
      const result = await optOutOfMFA();
      if (result) {
        setMfaPopup(false);
        dispatch({
          type: actionTypes.SET_LOADING,
          loading: false,
        });
      }
    } catch (error) {
      dispatch({
        type: actionTypes.SET_LOADING,
        loading: false,
      });
      if (error === "Please reauthenticate to complete this action") {
        setReauthenticatePopup(true);
      }
    }
  };

  return (
    <>
      <div
        style={{
          width: "80vw",
          margin: "0 auto",
          marginTop: "110px",
          padding: "20px 0",
        }}
      >
        <div id="recaptcha-container"></div>
        <h1>Welcome back, {user.displayName}!</h1>

        <div className="referralContainer">
          <div className="referralActions">
            <h2>
              Invite your friends, <span>get $10</span>
            </h2>
            <p>
              Introduce your friends to a better way of filing taxes. Refer them
              to Patel Accounting & Tax Oshawa branch and get $10 for each
              successful application.
            </p>
            {/* Referral Code copy */}
            <div
              className="referralCodeCopyContainer"
              onClick={() => {
                navigator.clipboard.writeText(referralCode);
                setCopiedReferralCode(true);
                setTimeout(() => {
                  setCopiedReferralCode(false);
                }, 2000);
              }}
            >
              <p>{referralCode}</p>
              <IconButton
                onClick={() => {
                  navigator.clipboard.writeText(referralCode);
                  setCopiedReferralCode(true);
                  setTimeout(() => {
                    setCopiedReferralCode(false);
                  }, 2000);
                }}
              >
                {copiedReferralCode ? (
                  <DoneAllRoundedIcon
                    style={{
                      fontSize: "1.5rem",
                      color: "green",
                    }}
                  />
                ) : (
                  <CopyAllRoundedIcon
                    style={{
                      fontSize: "1.5rem",
                      color: "rgb(79, 79, 79)",
                    }}
                  />
                )}
              </IconButton>
            </div>
          </div>
          <div className="referralBanner">
            <img src={ReferralSvg} alt="Referral Banner" />
          </div>
        </div>

        <div className="taxApplicationContainer">
          <h2>Tax Applications</h2>
          <p>
            Start a new application or view the status of a previously filed
            application. Make sure to have all your documents ready before you
            start an application.
          </p>
          <div className="selectTaxYearContainer">
            <label htmlFor="taxYear">Select Tax Year:</label>
            <select
              name="taxYear"
              id="taxYear"
              onChange={(e) => {
                e.preventDefault();
                setSelectedTaxYear(Number(e.target.value));
              }}
              value={selectedTaxYear}
            >
              <option value={currentTaxYear} key={currentTaxYear}>
                {currentTaxYear}
              </option>
              {filedPersonalApplication
                .filter((year) => year !== currentTaxYear)
                .map((application) => (
                  <option value={application} key={application}>
                    {application}
                  </option>
                ))}
            </select>
          </div>
          <div className="personalApplicationContainer">
            <h3>Personal Application</h3>
            <p>
              <span style={{ fontWeight: "bold" }}>Status:</span>{" "}
              {applicationStatus ? applicationStatus : "Not Submitted"}
            </p>
            <p>
              <span style={{ fontWeight: "bold" }}>Submitted On:</span>{" "}
              {applicationSubmittedOn
                ? applicationSubmittedOn
                : "Not Submitted"}
            </p>

            {filedPersonalApplication.includes(selectedTaxYear) ? (
              <Button
                variant="contained"
                color="primary"
                onClick={(e) =>
                  emailVerified
                    ? viewApplication(e, "personal")
                    : setRequiredVerifiedEmail(true)
                }
              >
                View Application
              </Button>
            ) : (
              <Button
                variant="contained"
                color="primary"
                onClick={(e) =>
                  emailVerified
                    ? startApplication(e, "personal")
                    : setRequiredVerifiedEmail(true)
                }
              >
                Start Personal Application
              </Button>
            )}
          </div>
        </div>

        <div className="mfaContainer">
          <h2>Two Factor Authentication</h2>
          <p>
            For added security, you can enable Multi-Factor Authentication (MFA)
            to protect your account. MFA requires you to verify your identity
            using a second form of verification after you sign in.
          </p>
          {multiFactor(auth.currentUser).enrolledFactors.length === 0 ? (
            <Button
              variant="contained"
              color="primary"
              style={{ backgroundColor: "#3498db" }}
              onClick={(e) => {
                emailVerified
                  ? setMfaPopup(true)
                  : setRequiredVerifiedEmail(true);
              }}
            >
              Enable
            </Button>
          ) : (
            <Button variant="contained" color="error" onClick={optOutListener}>
              Disable
            </Button>
          )}
        </div>

        {/* <div className="generalKnowledgeContainer">
          <h2 style={{ margin: "10px 0px" }}>General Knowledge</h2>
          <p>
            Increase your general knowledge by taking a quiz on tax laws and
            regulations
          </p>
          <Button variant="contained" color="primary">
            Increase general knowledge
          </Button>
        </div> */}
      </div>

      {mfaPopup && (
        <MFAWorkflow workflow={"MFAOptIn"} setTogglePopup={setMfaPopup} />
      )}
      {reauthenticatePopup && (
        <MFAWorkflow
          workflow={"MFAOptOut"}
          setTogglePopup={setReauthenticatePopup}
        />
      )}
      {emailVerificationPopup && (
        <EmailVerificationReq setTogglePopup={setEmailVerificationPopup} />
      )}
      {requiredVerifiedEmail && (
        <div className="popup-container" style={{ marginTop: "0px" }}>
          <div className="model_container">
            <h1 style={{ textAlign: "center", marginBottom: "1rem" }}>
              Email Verification Required
            </h1>
            <p>
              Please verify your account before you can proceed with this
              action. You will receive an email with a verification link.
            </p>
            <p>
              Once that process is complete, refresh the website to enable
              remote tax document submission.
            </p>

            <div className="button_container">
              <Button
                variant="outlined"
                onClick={() => setRequiredVerifiedEmail(false)}
                style={{ margin: "0.5rem 0.5rem" }}
              >
                Cancel
              </Button>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default RegularDashboard;
