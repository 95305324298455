import { Button } from "@mui/material";
import React, { useEffect, useState } from "react";
import "./Popup.scss";
import { collection, onSnapshot, query, where } from "firebase/firestore";
import db from "../../firebase";
import { useStateValue } from "../../StateProvider";

function UpdateStatus({
  setTogglePopup,
  currentStatus,
  currentNote,
  assignedTo,
  office,
  requireReviseDocuments,
  handleSubmit,
  error,
}) {
  const [{ role }] = useStateValue();
  const [status, setStatus] = useState(currentStatus);
  const [note, setNote] = useState(currentNote);
  const [assignTo, setAssignTo] = useState(assignedTo);
  const [officeLocation, setOfficeLocation] = useState(office);
  const [employees, setEmployees] = useState({});
  const [revisedApplication, setRevisedApplication] = useState(
    requireReviseDocuments
  );

  useEffect(() => {
    if (role === "Admin") {
      const employeeRef = query(
        collection(db, "users"),
        where("role", "in", ["Admin", "Employee"])
      );
      const fetchEmployees = onSnapshot(employeeRef, (snapshot) => {
        setEmployees({});
        snapshot.docs.map((doc) =>
          setEmployees((prev) => ({
            ...prev,
            [doc.id]: `${doc.data().firstName} ${doc.data().lastName}`,
          }))
        );
      });
      return () => fetchEmployees();
    }
  }, []);

  return (
    <form
      className="popup-container"
      style={{ marginTop: "0px" }}
      onSubmit={(e) =>
        handleSubmit(
          e,
          status,
          assignTo,
          officeLocation,
          note,
          revisedApplication
        )
      }
    >
      <div className="model_container">
        <h2>Update Status</h2>
        <div className="input_container">
          <label htmlFor="status">Status</label>
          <select
            name="status"
            id="status"
            value={status}
            onChange={(e) => setStatus(e.target.value)}
          >
            <option value="To Do">To Do</option>
            <option value="In Progress">In Progress</option>
            <option value="Blocked">Blocked</option>
            <option value="Completed">Completed</option>
          </select>
        </div>
        {role === "Admin" && (
          <>
            <div className="input_container">
              <label htmlFor="office">Assigned Office</label>
              <select
                name="office"
                id="office"
                value={officeLocation}
                onChange={(e) => setOfficeLocation(e.target.value)}
              >
                <option value="Scarborough">Scarborough</option>
                <option value="Oshawa">Oshawa</option>
              </select>
            </div>
            <div className="input_container">
              <label htmlFor="assignTo">Assign To</label>
              <select
                name="assignTo"
                id="assignTo"
                value={assignTo}
                onChange={(e) => setAssignTo(e.target.value)}
              >
                <option value="-">-</option>
                {Object.keys(employees).map((employee) => (
                  <option key={employee} value={employee}>
                    {employees[employee]}
                  </option>
                ))}
              </select>
            </div>
            <div className="input_container">
              <label htmlFor="requestRevisedApplication">
                Request Revised Application
              </label>
              <select
                name="requestRevisedApplication"
                id="requestRevisedApplication"
                value={revisedApplication}
                onChange={(e) => setRevisedApplication(e.target.value)}
              >
                <option value="false">No</option>
                <option value="true">Yes</option>
              </select>
            </div>
          </>
        )}
        <div className="input_container">
          <label htmlFor="notes">Notes</label>
          <textarea
            name="notes"
            id="notes"
            cols="30"
            rows="10"
            value={note}
            {...(status === "In Progress" || status === "Blocked"
              ? { required: true }
              : { required: false })}
            onChange={(e) => setNote(e.target.value)}
            placeholder="Enter notes here..."
          ></textarea>
        </div>
        {error && <p className="error">{error}</p>}
        <div className="button_container">
          <Button variant="outlined" onClick={(e) => setTogglePopup(false)}>
            Cancel
          </Button>
          <Button variant="contained" type="Submit">
            Update
          </Button>
        </div>
      </div>
    </form>
  );
}

export default UpdateStatus;
