import React, { useEffect, useState } from "react";
import "./Navbar.scss";
import OrgInfo from "../OrgInfo/OrgInfo";
import { Email, LocationOn, Phone } from "@mui/icons-material";
import { Button } from "@mui/material";
import { auth } from "../../firebase";
import { useNavigate } from "react-router-dom";
import { useStateValue } from "../../StateProvider";

function Navbar() {
  const [{ user, role, emailVerified }, dispatch] = useStateValue();
  const [isExpended, setIsExpanded] = useState(false);
  const navigate = useNavigate();

  const expandMenuBtn = (e, item = "") => {
    e.preventDefault();
    if (item === "menuItem") {
      setIsExpanded(false);
      return;
    }
    setIsExpanded(!isExpended);
  };

  const handleLoginOrLogout = (e) => {
    e.preventDefault();
    if (auth.currentUser) {
      dispatch({
        type: "SET_CLEANUP",
      });
      auth.signOut();
    } else {
      navigate("/login");
    }
  };

  return (
    <header className="header" id="header">
      <div className="logoContainer">
        <img
          src="/images/patel-accounting-taxation-logo.png"
          alt=""
          className="logo"
          id="home"
        />
      </div>

      <label className={`menu-icon${isExpended ? " active" : ""}`}>
        <div>
          <Button
            variant="contained"
            className="actionBtn"
            onClick={handleLoginOrLogout}
          >
            {auth.currentUser ? "Logout" : "Login"}
          </Button>
        </div>
        <div className="navIcon-container" onClick={expandMenuBtn}>
          <span className="navicon"></span>
        </div>
      </label>

      <div className="navContainer">
        <div className="information_wrapper">
          <div className="call-wrapper">
            <OrgInfo
              Logo={Phone}
              InfoMessage={() => {
                return <a href="tel:(647) 870-7907">(647) 870-7907</a>;
              }}
            />
            <OrgInfo
              Logo={Phone}
              InfoMessage={() => {
                return <a href="tel:(365) 443-3444">(365) 443-3444</a>;
              }}
            />
          </div>

          <OrgInfo
            Logo={Email}
            InfoMessage={() => {
              return (
                <a
                  href="mailto:info@pateltaxation.ca"
                  style={{ textTransform: "lowercase" }}
                >
                  info@pateltaxation.ca
                </a>
              );
            }}
          />

          <OrgInfo
            Logo={LocationOn}
            InfoMessage={() => {
              return (
                <a
                  href="https://www.google.com/maps/dir//Patel+Accounting+and+Taxation,+2100+Ellesmere+Rd+Suite+112,+Scarborough,+ON+M1H+3C3/@43.8282285,-79.2102077,12z"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Scarborough, ON
                </a>
              );
            }}
          />

          <OrgInfo
            Logo={LocationOn}
            InfoMessage={() => {
              return (
                <a
                  href="https://www.google.com/maps/dir//94+Taunton+Road+West,+Oshawa,+ON/@43.8950903,-79.0392451,12z"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Oshawa, ON
                </a>
              );
            }}
          />
        </div>

        <div className="linkWrapper">
          <ul className="menu">
            {user ? (
              <>
                <li
                  onClick={(e) => {
                    setIsExpanded(false);
                    navigate("/dashboard");
                  }}
                >
                  <a>Dashboard</a>
                </li>
                <li
                  onClick={(e) => {
                    setIsExpanded(false);
                    // navigate("/");
                  }}
                >
                  <a>Resources</a>
                </li>
                <li
                  onClick={(e) => {
                    setIsExpanded(false);
                    // navigate("/");
                  }}
                >
                  <a>Tax Checklist</a>
                </li>
              </>
            ) : (
              <>
                <li
                  onClick={(e) => {
                    setIsExpanded(false);
                    navigate("/");
                  }}
                >
                  <a href="#homepage">Home</a>
                </li>
                <li
                  onClick={(e) => {
                    setIsExpanded(false);
                    navigate("/");
                  }}
                >
                  <a href="#services">Services</a>
                </li>
                <li
                  onClick={(e) => {
                    setIsExpanded(false);
                  }}
                >
                  <a href="#referAFriend">Refer a Friend</a>
                </li>
                <li
                  onClick={(e) => {
                    setIsExpanded(false);
                    navigate("/");
                  }}
                >
                  <a href="#contactUs">Contact Us</a>
                </li>
              </>
            )}
          </ul>
          <Button
            variant="outlined"
            className="actionBtn"
            onClick={handleLoginOrLogout}
          >
            {auth.currentUser ? "Logout" : "Login"}
          </Button>
        </div>
      </div>
    </header>
  );
}

export default Navbar;
