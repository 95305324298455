import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import "ag-grid-community/styles/ag-theme-quartz.css";
import { collection, doc, onSnapshot, updateDoc } from "firebase/firestore";
import React, { useCallback, useEffect, useRef, useState } from "react";
import db from "../../../firebase";
import { AgGridReact } from "ag-grid-react";
import { useStateValue } from "../../../StateProvider";
import { useNavigate } from "react-router-dom";

function RoleManagement() {
  const [{ user, role }, dispatch] = useStateValue();
  const navigate = useNavigate();
  const gridRef = useRef(null);
  const usersRef = collection(db, "users");
  const [users, setUsers] = useState([]);
  const columns = [
    {
      field: "uid",
      headerName: "UID",
      hide: true,
    },
    {
      field: "firstName",
      headerName: "First Name",
      sortable: true,
      filter: true,
    },
    {
      field: "lastName",
      headerName: "Last Name",
      sortable: true,
      filter: true,
    },
    {
      field: "email",
      headerName: "Email",
      sortable: true,
      filter: true,
    },
    {
      field: "role",
      headerName: "Role",
      sortable: true,
      filter: true,
      cellEditor: "agSelectCellEditor",
      cellEditorParams: {
        values: ["Regular", "Admin", "Employee"],
        valueListMaxHeight: 200,
      },
      editable: true,
    },
    {
      field: "assignedTo",
      headerName: "Assigned To",
      cellEditor: "agSelectCellEditor",
      cellEditorParams: {
        values: ["N/A", "All", "Oshawa", "Scarborough"],
        valueListMaxHeight: 200,
      },
      editable: true,
      sortable: true,
      filter: true,
    },
  ];
  const onGridReady = useCallback((params) => {
    if (window.innerWidth >= 1170) {
      sizeToFit();
    }
  }, []);
  const sizeToFit = useCallback(() => {
    gridRef.current.api.sizeColumnsToFit({
      defaultMinWidth: 200,
    });
  }, []);

  const onCellValueChanged = useCallback((params) => {
    if (params.oldValue !== params.newValue) {
      const userRef = doc(collection(db, "users"), params.data.uid);
      switch (params.column.colId) {
        case "role":
          if (params.newValue === "Employee") {
            updateDoc(userRef, {
              role: params.newValue,
              assignedTo: "Oshawa",
            });
          } else if (params.newValue === "Admin") {
            updateDoc(userRef, {
              role: params.newValue,
              assignedTo: "All",
            });
          } else {
            updateDoc(userRef, {
              role: params.newValue,
              assignedTo: "N/A",
            });
          }
          break;
        case "assignedTo":
          updateDoc(userRef, {
            assignedTo: params.newValue,
          });
          break;
        default:
          break;
      }
    }
  }, []);

  useEffect(() => {
    if (role !== "Admin") {
      navigate("/");
    }
    if (role === "Admin") {
      const unsubscribe = onSnapshot(usersRef, (snapshot) => {
        setUsers([]);
        snapshot.docs.forEach((doc) => {
          if (doc.id !== user.uid) {
            setUsers((prev) => [
              ...prev,
              {
                uid: doc.id,
                firstName: doc.data().firstName,
                lastName: doc.data().lastName,
                email: doc.data().email,
                role:
                  doc.data()?.role === undefined ? "Regular" : doc.data().role,
                assignedTo:
                  doc.data()?.role === undefined ||
                  doc.data()?.role === "Regular"
                    ? "N/A"
                    : doc.data()?.role === "Admin"
                    ? "All"
                    : doc.data()?.assignedTo,
              },
            ]);
          }
        });
      });
      return () => {
        unsubscribe();
      };
    }
  }, []);

  return (
    <div style={{ padding: "10px" }}>
      <h2>Role Management</h2>
      <div className="filter-container">
        <input
          type="text"
          placeholder="Search"
          className="filter-input"
          id="filterInput"
          onChange={(e) => {
            gridRef.current.api.setQuickFilter(e.target.value);
          }}
        />
      </div>
      <div style={{ height: "70vh", width: "100%", marginTop: "10px" }}>
        <div className="ag-theme-quartz" style={{ height: "100%" }}>
          <AgGridReact
            ref={gridRef}
            rowData={users}
            columnDefs={columns}
            onGridReady={onGridReady}
            onGridSizeChanged={sizeToFit}
            onCellValueChanged={onCellValueChanged}
            pagination={true}
            paginationPageSize={20}
            animateRows={true}
            cacheQuickFilter={true}
            suppressScrollOnNewData={true}
          ></AgGridReact>
        </div>
      </div>
    </div>
  );
}

export default RoleManagement;
