import React from "react";
import "./Footer.scss";
import OrgInfo from "../OrgInfo/OrgInfo";
import { AccessTimeFilled, LocationOn, Mail, Phone } from "@mui/icons-material";

function Footer() {
  const footerDetails = [
    {
      name: "Useful Links",
      details: [
        {
          title: "Home",
          href: "/",
        },
        {
          title: "About Us",
          href: "/about-us",
        },
        {
          title: "Things To Bring",
          href: "/contact-us",
        },
        {
          title: "Contact Us",
          href: "/contact-us",
        },
      ],
    },
    {
      name: "Services",
      details: [
        {
          title: "Personal Tax Return",
          href: "/",
        },
        {
          title: "Corporate Tax Return",
          href: "/",
        },
        {
          title: "HST Return and Rebate",
          href: "/",
        },
        {
          title: "Accounting",
          href: "/",
        },
        {
          title: "Payroll Services",
          href: "/",
        },
        {
          title: "Business Planning",
          href: "/",
        },
        {
          title: "Business Registration and Corporate filing",
          href: "/",
        },
      ],
    },
    {
      name: "Government Links",
      details: [
        {
          title: "City of Toronto",
          href: "https://www.toronto.ca/",
        },
        {
          title: "City of Brampton",
          href: "https://www.brampton.ca/en/pages/welcome.aspx",
        },
        {
          title: "City of Mississauga",
          href: "http://www.mississauga.ca/portal/home",
        },
        {
          title: "Region of Peel",
          href: "https://www.peelregion.ca/",
        },
        {
          title: "Government of Canada",
          href: "https://www.canada.ca/en.html",
        },
        {
          title: "Government of Ontario",
          href: "https://www.ontario.ca/page/government-ontario",
        },
      ],
    },
    {
      name: "Taxation",
      details: [
        {
          title: "Canada Revenue Agency",
          href: "https://www.canada.ca/en/revenue-agency.html",
        },
        {
          title: "Ministry of Finance",
          href: "https://www.ontario.ca/page/ministry-finance",
        },
        {
          title: "GST/HST",
          href: "https://www.canada.ca/en/services/taxes/gsthst.html",
        },
        {
          title: "Payroll Taxes",
          href: "https://www.canada.ca/en/services/taxes/payroll.html",
        },
        {
          title: "Employment Insurance",
          href: "https://www.canada.ca/en/services/benefits/ei.html",
        },
      ],
    },
  ];

  return (
    <div className="footer">
      <div className="information-container">
        <div
          style={{
            display: "flex",
            flexWrap: "wrap",
            gap: "20px",
          }}
        >
          <div className="location-container">
            <OrgInfo
              Logo={LocationOn}
              InfoMessage={() => {
                return (
                  <>
                    <h5>Scarborough Office</h5>
                    <p>
                      2100 Ellesmere Road, Suite 112 <br /> Scarborough,
                      <br /> ON M1H 3C3
                    </p>
                  </>
                );
              }}
            />
            <OrgInfo
              Logo={Phone}
              InfoMessage={() => {
                return <a href="tel:(647) 870-7907">(647) 870-7907</a>;
              }}
            />
            <OrgInfo
              Logo={Mail}
              InfoMessage={() => {
                return (
                  <a href="mailto:info@pateltaxation.ca">
                    info@pateltaxation.ca
                  </a>
                );
              }}
            />
            <OrgInfo
              Logo={AccessTimeFilled}
              InfoMessage={() => {
                return (
                  <>
                    <h5>Hours of Operations</h5>
                    <p>
                      Special Hours - 10am - 9am (from 1st Feb - 30th April)
                    </p>
                    <p>Regular Hours - 10am - 5am or by appointment</p>
                  </>
                );
              }}
            />
          </div>
          <div className="location-container">
            <OrgInfo
              Logo={LocationOn}
              InfoMessage={() => {
                return (
                  <>
                    <h5>Oshawa Office</h5>
                    <p>
                      94 Taunton Rd W, <br />
                      Oshawa, ON L1G 3T2
                    </p>
                  </>
                );
              }}
            />
            <OrgInfo
              Logo={Phone}
              InfoMessage={() => {
                return <a href="tel:(365) 443-3444">(365) 443-3444</a>;
              }}
            />
            <OrgInfo
              Logo={Mail}
              InfoMessage={() => {
                return <a href="mailto:info@pateltaxation.ca">oshawa.ca</a>;
              }}
            />
          </div>
        </div>
      </div>

      {footerDetails.map((section, index) => (
        <div className="section-container" key={`section-${index}`}>
          <h4>{section.name}</h4>
          {section.details.map((subsections, subsectionIdx) => (
            <a key={subsectionIdx} href={subsections.href}>
              {subsections.title}
            </a>
          ))}
        </div>
      ))}
    </div>
  );
}

export default Footer;
