import { Button, IconButton } from "@mui/material";
import React, { useEffect, useState } from "react";
import DeleteIcon from "@mui/icons-material/Delete";

const FileInput = ({
  label,
  section,
  documentType,
  status = "",
  uploadedFiles,
  handleFileUpload,
  handleFileRemove,
}) => {
  return (
    <div className="form-input">
      <div className="form-file-input">
        <h3 style={{ textTransform: "capitalize" }}>
          {label === "voidCheque"
            ? "Void Cheque"
            : label === "otherDocuments"
            ? "Other Documents"
            : label === "t4a"
            ? "T4A"
            : label}
        </h3>
        {status !== "viewData" && (
          <label
            htmlFor={`${section}-${documentType}`}
            className="custom-file-button"
          >
            Upload
          </label>
        )}
        <input
          type="file"
          name={`${section}-${documentType}`}
          id={`${section}-${documentType}`}
          accept="image/*,.pdf"
          multiple
          disabled={status === "viewData"}
          onChange={(e) => handleFileUpload(e, section, documentType)}
        />
      </div>
      {uploadedFiles &&
        Object.keys(uploadedFiles).map((fileName, index) => (
          <div
            key={index}
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <p
              style={{
                maxWidth: "230px",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
                overflow: "hidden",
              }}
            >
              {fileName}
            </p>
            {status !== "viewData" && (
              <IconButton
                color="error"
                size="small"
                onClick={(e) =>
                  handleFileRemove(e, section, documentType, fileName)
                }
              >
                <DeleteIcon />
              </IconButton>
            )}
          </div>
        ))}
    </div>
  );
};

export default FileInput;
