import React, { useState } from "react";
import "./Popup.scss";
import { Alert, Button } from "@mui/material";
import {
  auth,
  finishEnrollMultiFactor,
  optOutOfMFA,
  phoneAuthProvider,
  resolveSignInVerificationProcess,
  startEnrollMultiFactor,
} from "../../firebase";
import CheckCircleOutlineRoundedIcon from "@mui/icons-material/CheckCircleOutlineRounded";
import Reauthenticate from "./Reauthenticate";
import { RecaptchaVerifier, getMultiFactorResolver } from "firebase/auth";
import { useStateValue } from "../../StateProvider";
import { actionTypes } from "../../Reducer";
import VerificationCode from "./VerificationCode";
import RequiredInput from "../Buttons/RequiredInput";

/**
 *
 * @param {Boolean} setTogglePopup
 * @param {string} workflow - MFAOptIn, MFAOptOut, auth
 * @returns
 */
function MFAWorkflow({ setTogglePopup, workflow }) {
  const workflowMapping = {
    MFAOptIn: 0, // Go through steps 0, 1, 2, 3
    MFAOptOut: 1, // Go through steps 0, 1, 2, 4
    auth: 1, // Go through steps 1, 2
  };

  const stepMapping = {
    PhoneNumber: 0,
    Reauthenticate: 1,
    VerificationCode: 2,
    Success: 3,
    UnEnrollSuccess: 4,
  };
  const [{}, dispatch] = useStateValue();

  const [step, setStep] = useState(workflowMapping[workflow]);
  const [phoneNumber, setPhoneNumber] = useState("");
  const [verificationId, setVerificationId] = useState("");
  const [code, setCode] = useState(new Array(6).fill(""));
  const [verificationError, setVerificationError] = useState("");
  const [resolver, setResolver] = useState(null);

  const sendVerificationCode = async (e) => {
    e.preventDefault();
    try {
      dispatch({
        type: actionTypes.SET_LOADING,
        loading: true,
      });

      let vId = await startEnrollMultiFactor(phoneNumber);
      if (vId) {
        setVerificationId(vId);
        setStep(stepMapping["VerificationCode"]);
        dispatch({
          type: actionTypes.SET_LOADING,
          loading: false,
        });
      } else {
        console.log("Failed to send verification code");
        dispatch({
          type: actionTypes.SET_LOADING,
          loading: false,
        });
      }
    } catch (error) {
      dispatch({
        type: actionTypes.SET_LOADING,
        loading: false,
      });
      switch (error) {
        case "Please reauthenticate to complete this action":
          setStep(stepMapping["Reauthenticate"]);
          break;
        case "Second factor already in use":
          console.log("Second factor already in used");
          break;
        case "User not signed in":
          console.log("User not signed in");
          break;
        default:
          console.log("Error: ", error);
          break;
      }
    }
  };

  const verifyCode = async (e) => {
    e.preventDefault();
    try {
      let result;
      if (workflow === "MFAOptIn") {
        result = await finishEnrollMultiFactor(code.join(""), verificationId);
        if (result) {
          setStep(stepMapping["Success"]);
        } else {
          setVerificationError("Failed to verify code");
          // TODO: Send another code
          sendVerificationCode(e);
        }
      } else {
        result = await resolveSignInVerificationProcess(
          code.join(""),
          verificationId,
          resolver
        );
        if (result) {
          switch (workflow) {
            case "MFAOptOut":
              optOutOfMFA()
                .then((result) => {
                  console.log("Result: ", result);
                  if (result) {
                    setStep(stepMapping["UnEnrollSuccess"]);
                  }
                })
                .catch((error) => {
                  console.log("Error: ", error);
                });
              break;
            case "auth":
              dispatch({
                type: actionTypes.SET_LOGIN_USER,
                user: result.user,
                displayName: result.user.displayName,
              });
              setTogglePopup(false);
              break;
            default:
              break;
          }
        } else {
          setVerificationError("Failed to verify code");
        }
      }
    } catch (error) {
      switch (error) {
        case "Invalid verification code":
          setVerificationError("Invalid verification code");
          break;
        default:
          console.log("Error: ", error);
          break;
      }
    }
  };

  const authReauthenticate = (e, error) => {
    e.preventDefault();
    const recaptchaContainer = document.getElementById("recaptcha-container");
    if (recaptchaContainer) {
      recaptchaContainer.outerHTML = '<div id="recaptcha-container"></div>';
    }
    const recaptchaVerifier = new RecaptchaVerifier(
      auth,
      "recaptcha-container",
      {
        size: "invisible",
      }
    );

    let tempResolver = getMultiFactorResolver(auth, error);
    setResolver(tempResolver);
    const phoneInfoOptions = {
      multiFactorHint: tempResolver.hints[0],
      session: tempResolver.session,
    };
    phoneAuthProvider
      .verifyPhoneNumber(phoneInfoOptions, recaptchaVerifier)
      .then((verificationId) => {
        setVerificationId(verificationId);
        setStep(stepMapping["VerificationCode"]);
        dispatch({
          type: actionTypes.SET_LOADING,
          loading: false,
        });
      })
      .catch((error) => {
        recaptchaVerifier.clear();
        dispatch({
          type: actionTypes.SET_LOADING,
          loading: false,
        });
        console.log("Error sending verification code: ", error);
      });
  };

  return (
    <div className="popup-container" style={{ marginTop: "0px" }}>
      <div className="model_container">
        <div id="recaptcha-container"></div>
        {step === 0 ? (
          <form className="input_container" onSubmit={sendVerificationCode}>
            <h1 style={{ margin: "1rem 0", marginBottom: "0.5rem" }}>
              Provide Phone Number to Enroll
            </h1>
            <p
              style={{
                margin: "0.3rem 0",
                color: "rgb(79, 79, 79)",
                marginBottom: "0",
              }}
            >
              Fill in your phone number to receive codes
            </p>
            <RequiredInput
              id="phoneNumber"
              inputType="phoneNumber"
              type="tel"
              currValue={phoneNumber}
              onChangeVariable={setPhoneNumber}
              errorMessage="Invalid phone number"
              required
            />

            <div className="button_container">
              <Button variant="outlined" onClick={() => setTogglePopup(false)}>
                Cancel
              </Button>
              <Button variant="contained" type="submit">
                Send SMS
              </Button>
            </div>
          </form>
        ) : step === 1 ? (
          <Reauthenticate
            sendVerificationCode={sendVerificationCode}
            authReauthenticate={authReauthenticate}
            setTogglePopup={setTogglePopup}
          />
        ) : step === 2 ? (
          <VerificationCode
            verifyCode={verifyCode}
            setTogglePopup={setTogglePopup}
            code={code}
            setCode={setCode}
          />
        ) : step === 3 ? (
          <div className="input_container">
            <CheckCircleOutlineRoundedIcon
              style={{ fontSize: "3rem", color: "green" }}
            />
            <h1>Successfully Enrolled</h1>
            <p>You have successfully added a new authentication factor</p>
            <div className="button_container">
              <Button variant="contained" onClick={() => setTogglePopup(false)}>
                Close
              </Button>
            </div>
          </div>
        ) : step === 4 ? (
          <div className="input_container">
            <CheckCircleOutlineRoundedIcon
              style={{ fontSize: "3rem", color: "green" }}
            />
            <h1>Successfully Unenrolled</h1>
            <p>
              You have successfully removed a authentication factor from your
              account
            </p>
            <div className="button_container">
              <Button variant="contained" onClick={() => setTogglePopup(false)}>
                Close
              </Button>
            </div>
          </div>
        ) : (
          <p>Something went wrong</p>
        )}
      </div>
    </div>
  );
}

export default MFAWorkflow;
