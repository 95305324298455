import React, { useEffect } from "react";
import { useStateValue } from "../../StateProvider";
import "./Dashboard.scss";
import { Button } from "@mui/material";
import { useNavigate } from "react-router-dom";

function AdminDashboard() {
  const [{ user, role }, dispatch] = useStateValue();
  const history = useNavigate();

  return (
    <div className="AdminDashboard" style={{ padding: "10px" }}>
      <h1>Welcome {user.displayName}!</h1>

      {/* <div className="statsContainer">
        <div className="stat">
          <h3>Users</h3>
          <p>0</p>
        </div>
        <div className="stat">
          <h3>Applications</h3>
          <p>0</p>
        </div>
        <div className="stat">
          <h3>Revenue</h3>
          <p>$0</p>
        </div>
      </div> */}

      <div className="adminActionsContainer">
        <h2 style={{ margin: "10px 0px" }}>Admin Actions</h2>
        <div style={{ display: "flex", gap: "10px" }}>
          <Button
            variant="contained"
            color="primary"
            onClick={(e) => {
              history("/admin/roles");
            }}
          >
            Role Management
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={(e) => history("/admin/referrals")}
          >
            Referrals
          </Button>
        </div>
        <h2 style={{ margin: "10px 0px", marginTop: "15px" }}>
          Tax Applications
        </h2>
        <div style={{ display: "flex", gap: "10px" }}>
          <Button
            variant="contained"
            color="primary"
            onClick={(e) => history("/admin/personal-applications")}
          >
            Personal Applications
          </Button>
          {/* <Button
            variant="contained"
            color="primary"
            onClick={(e) => history("/admin/incorporation-applications")}
          >
            Incorporation Applications
          </Button> */}
        </div>
      </div>
    </div>
  );
}

export default AdminDashboard;
