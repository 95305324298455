import { Alert, Button } from "@mui/material";
import React, { useState } from "react";

function VerificationCode({ verifyCode, setTogglePopup, code, setCode }) {
  const [verificationError, setVerificationError] = useState("");

  return (
    <form className="input_container" onSubmit={verifyCode}>
      <h1 style={{ margin: "1rem 0", marginBottom: "0.5rem" }}>
        Verification Code
      </h1>
      <p
        style={{
          margin: "0.3rem 0",
          color: "rgb(79, 79, 79)",
          marginBottom: "0",
        }}
      >
        Enter the verification code sent to your phone
      </p>
      <div className="verification-code">
        {/* How to automatically move to the next element once filled instead of tapping tab */}
        {[...Array(6)].map((_, i) => (
          <input
            key={i}
            required
            name={`code${i}`}
            type="text"
            pattern="^[0-9]"
            maxLength="1"
            size="1"
            defaultValue={code[i]}
            onChange={(e) => {
              setVerificationError("");
              const value = e.target.value;
              if (value.length > 1) return;
              setCode((prev) => {
                let newCode = [...prev];
                newCode[i] = value;
                return newCode;
              });
              if (i < 5 && value.length > 0) {
                document.querySelector(`input[name=code${i + 1}]`).focus();
              }
            }}
          />
        ))}
      </div>
      {verificationError && (
        <Alert severity="error" className="errorMessage">
          {verificationError}
        </Alert>
      )}
      <div className="button_container">
        <Button variant="outlined" onClick={() => setTogglePopup(false)}>
          Cancel
        </Button>
        <Button variant="contained" type="submit">
          Verify
        </Button>
      </div>
    </form>
  );
}

export default VerificationCode;
