// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getAnalytics } from "firebase/analytics";
import {
  GoogleAuthProvider,
  PhoneAuthProvider,
  PhoneMultiFactorGenerator,
  RecaptchaVerifier,
  getAuth,
  multiFactor,
  reauthenticateWithCredential,
  reauthenticateWithPopup,
} from "firebase/auth";
import { getStorage } from "firebase/storage";
import { initializeAppCheck, ReCaptchaV3Provider } from "firebase/app-check";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyB9RQ4SSiU1IcBpICbx0Ku7cD_zjXxDYTM",
  authDomain: "patel-taxation.firebaseapp.com",
  projectId: "patel-taxation",
  storageBucket: "patel-taxation.appspot.com",
  messagingSenderId: "46098656794",
  appId: "1:46098656794:web:79a91c079389a53dcbaba3",
  measurementId: "G-NYRW1SG8XM",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const db = getFirestore(app);
const auth = getAuth(app);
// auth.settings.appVerificationDisabledForTesting = true;
const appCheck = initializeAppCheck(app, {
  provider: new ReCaptchaV3Provider("6LcEQXspAAAAAH-a19Fi3EaJ75gjivRPVmrF3_bK"),
  isTokenAutoRefreshEnabled: true,
});
const storage = getStorage(app);
const provider = new GoogleAuthProvider();
const phoneAuthProvider = new PhoneAuthProvider(auth);

export { auth, provider, storage, phoneAuthProvider };
export default db;

/**
 * Pass the input id if setting invisible recaptcha,
 * otherwise pass the container id where recaptcha will
 * be launch.
 */
export async function startEnrollMultiFactor(phoneNumber) {
  const recaptchaContainer = document.getElementById("recaptcha-container");
  if (recaptchaContainer) {
    recaptchaContainer.outerHTML = '<div id="recaptcha-container"></div>';
  }
  const recaptchaVerifier = new RecaptchaVerifier(auth, "recaptcha-container", {
    size: "invisible",
  });
  return new Promise((resolve, reject) => {
    if (auth.currentUser) {
      multiFactor(auth.currentUser)
        .getSession()
        .then(async (session) => {
          const phoneInfoOptions = {
            phoneNumber: phoneNumber,
            session: session,
          };
          // Send SMS verification code
          return phoneAuthProvider
            .verifyPhoneNumber(phoneInfoOptions, recaptchaVerifier)
            .catch((error) => {
              recaptchaVerifier.clear();
              switch (error.code) {
                case "auth/second-factor-already-in-use":
                  reject("Second factor already in use");
                  break;
                case "auth/requires-recent-login":
                  reject("Please reauthenticate to complete this action");
                  break;
                default:
                  reject("Something went wrong. Please try again.");
                  break;
              }
            });
        })
        .then((verificationId) => {
          resolve(verificationId);
        })
        .catch((error) => {
          recaptchaVerifier.clear();
          reject("Error starting second factor enrollment");
        });
    } else {
      reject("User not signed in");
    }
  });
}

export async function finishEnrollMultiFactor(
  verificationCode,
  verificationId
) {
  return new Promise(async (resolve, reject) => {
    if (verificationId && auth.currentUser) {
      const phoneCredential = PhoneAuthProvider.credential(
        verificationId,
        verificationCode
      );
      const multiFactorAssertion =
        PhoneMultiFactorGenerator.assertion(phoneCredential);
      await multiFactor(auth.currentUser)
        .enroll(multiFactorAssertion, auth.currentUser.displayName)
        .then(() => {
          resolve(true);
        })
        .catch((error) => {
          switch (error.code) {
            case "auth/invalid-verification-code":
              reject("Invalid verification code");
              break;
            case "auth/requires-recent-login":
              reject("Please reauthenticate to complete this action");
              break;
            default:
              console.error(
                `Error finishing second factor enrollment. ${error}`
              );
              reject("Something went wrong. Please try again.");
              break;
          }
        });
    } else {
      reject("User not signed in");
    }
  });
}

export async function resolveSignInVerificationProcess(
  verificationCode,
  verificationId,
  resolver
) {
  return new Promise((resolve, reject) => {
    if (verificationCode && verificationId) {
      const credential = PhoneAuthProvider.credential(
        verificationId,
        verificationCode
      );
      const multiFactorAssertion =
        PhoneMultiFactorGenerator.assertion(credential);
      resolver
        .resolveSignIn(multiFactorAssertion)
        .then((userCredential) => {
          resolve(userCredential);
        })
        .catch((error) => {
          reject(error);
        });
    }
  });
}

export function optOutOfMFA() {
  return new Promise((resolve, reject) => {
    // Check if the user is enroll in MFA.
    const checkMfaEnrollment = multiFactor(auth.currentUser).enrolledFactors;
    // Unenroll all the MFA entries in the current user by uid.
    for (let i = 0; i < checkMfaEnrollment.length; i++) {
      multiFactor(auth.currentUser)
        .unenroll(checkMfaEnrollment[i].uid)
        .then(() => {
          resolve(true);
        })
        .catch((error) => {
          switch (error.code) {
            case "auth/requires-recent-login":
              reject("Please reauthenticate to complete this action");
              break;
            default:
              console.error(`Error unenrolling MFA. ${error}`);
              reject("Something went wrong. Please try again.");
              break;
          }
        });
    }
  });
}

export function reauthenticateUser(password = "") {
  return new Promise((resolve, reject) => {
    const provider = auth.currentUser.providerData[0];
    switch (provider.providerId) {
      case "google.com":
        reauthenticateWithPopup(auth.currentUser, provider)
          .then((result) => {
            resolve(true);
          })
          .catch((e) => {
            switch (e.code) {
              case "auth/popup-blocked":
                console.log("Popup blocked");
                break;
              default:
                console.log("Error: ", e);
                break;
            }
          });
        break;
      default:
        reauthenticateWithCredential(auth.currentUser, {
          email: auth.currentUser.email,
          password: password,
        })
          .then((result) => {
            resolve(true);
          })
          .catch((e) => {
            switch (e.code) {
              case "auth/wrong-password":
                console.log("Wrong password");
                break;
              default:
                console.log("Error: ", e);
                break;
            }
          });
        break;
    }
  });
}
