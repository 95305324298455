import {
  EmailAuthProvider,
  reauthenticateWithCredential,
  reauthenticateWithPopup,
} from "firebase/auth";
import React, { useState } from "react";
import { auth, provider } from "../../firebase";
import RequiredInput from "../Buttons/RequiredInput";
import { Button, IconButton } from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import "./Popup.scss";
import { useStateValue } from "../../StateProvider";
import { actionTypes } from "../../Reducer";

function Reauthenticate({
  sendVerificationCode,
  setTogglePopup,
  authReauthenticate,
}) {
  const [password, setPassword] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [activeEye, setActiveEye] = useState(false);
  const [{ user }, dispatch] = useStateValue();

  const credentialReauthenticate = (e) => {
    e.preventDefault();

    dispatch({
      type: actionTypes.SET_LOADING,
      loading: true,
    });

    const credential = EmailAuthProvider.credential(
      auth.currentUser.email,
      password
    );

    reauthenticateWithCredential(auth.currentUser, credential)
      .then((result) => {
        dispatch({
          type: actionTypes.SET_LOADING,
          loading: false,
        });
        sendVerificationCode(e);
      })
      .catch((error) => {
        switch (error.code) {
          case "auth/wrong-password":
            dispatch({
              type: actionTypes.SET_LOADING,
              loading: false,
            });
            setPasswordError("Wrong password");
            break;
          case "auth/multi-factor-auth-required":
            authReauthenticate(e, error);
            break;
          default:
            dispatch({
              type: actionTypes.SET_LOADING,
              loading: false,
            });
            setPasswordError("Unable to reauthenticate. Please try again.");
            break;
        }
      });
  };

  const googleReauthenticate = (e) => {
    e.preventDefault();
    dispatch({
      type: actionTypes.SET_LOADING,
      loading: true,
    });
    reauthenticateWithPopup(auth.currentUser, provider)
      .then((result) => {
        dispatch({
          type: actionTypes.SET_LOADING,
          loading: false,
        });
        sendVerificationCode(e);
      })
      .catch((error) => {
        switch (error.code) {
          case "auth/multi-factor-auth-required":
            authReauthenticate(e, error);
            break;
          case "auth/popup-blocked":
            console.log("Popup blocked");
            dispatch({
              type: actionTypes.SET_LOADING,
              loading: false,
            });
            break;
          default:
            console.log("Error: ", e);
            dispatch({
              type: actionTypes.SET_LOADING,
              loading: false,
            });
            break;
        }
      });
  };

  const hide_viewPassword = () => {
    const passwordType = document.querySelector("#password");
    try {
      const type =
        passwordType.getAttribute("type") === "password" ? "text" : "password";
      if (type === "text") {
        setActiveEye(true);
      } else {
        setActiveEye(false);
      }
      passwordType.setAttribute("type", type);
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <form className="input_container" onSubmit={credentialReauthenticate}>
      <h1 style={{ margin: "1rem 0", marginBottom: "0.5rem" }}>
        Reauthenticate
      </h1>

      {auth.currentUser.providerData[0].providerId === "google.com" ? (
        <>
          <p
            style={{
              margin: "0.3rem 0",
              color: "rgb(79, 79, 79)",
              marginBottom: "0",
            }}
          >
            You are currently logged in with Google. Please reauthenticate with
            Google to complete this action.
          </p>
          <Button
            variant="contained"
            color="primary"
            onClick={(e) => googleReauthenticate(e)}
          >
            Reauthenticate with Google
          </Button>
        </>
      ) : (
        <>
          <p
            style={{
              margin: "0.3rem 0",
              color: "rgb(79, 79, 79)",
              marginBottom: "0",
            }}
          >
            Please reauthenticate to complete this action
          </p>
          <div className="password-field">
            <RequiredInput
              id="password"
              inputType="input"
              type="password"
              placeholder="Password"
              currValue={password}
              onChangeVariable={setPassword}
              pattern="(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}"
              errorMessage={
                passwordError
                  ? passwordError
                  : "Password must contain at least one number and one uppercase and lowercase letter, and at least 8 or more characters"
              }
              required
            />
            <IconButton onClick={hide_viewPassword} className="password-icon">
              {activeEye ? <VisibilityIcon /> : <VisibilityOffIcon />}
            </IconButton>
          </div>
          <div className="button_container">
            <Button variant="outlined" onClick={() => setTogglePopup(false)}>
              Cancel
            </Button>
            <Button variant="contained" type="submit">
              Reauthenticate
            </Button>
          </div>
        </>
      )}
    </form>
  );
}

export default Reauthenticate;
