import React, { useEffect, useState } from "react";
import "./SignIn.scss";
import { useNavigate } from "react-router-dom";
import {
  auth,
  finishEnrollMultiFactor,
  phoneAuthProvider,
  provider,
  resolveSignInVerificationProcess,
  startEnrollMultiFactor,
} from "../../firebase";
import {
  signInWithEmailAndPassword,
  signInWithPopup,
  sendPasswordResetEmail,
  RecaptchaVerifier,
  getMultiFactorResolver,
  PhoneAuthProvider,
  PhoneMultiFactorGenerator,
} from "@firebase/auth";
import { Alert, Button, IconButton } from "@mui/material";
import { actionTypes } from "../../Reducer";
import { useStateValue } from "../../StateProvider";
import RequiredInput from "../../Components/Buttons/RequiredInput";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { EightMpRounded } from "@mui/icons-material";
import VerificationCode from "../../Components/Popup/VerificationCode";

function SignIn() {
  const history = useNavigate();
  const [{}, dispatch] = useStateValue();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [forgotPassFlag, setForgotPassFlag] = useState(false);
  const [resetEmail, setResetEmail] = useState("");
  const [activeEye, setActiveEye] = useState(false);
  const [loginError, setLoginError] = useState("");
  const [verificationId, setVerificationId] = useState("");
  const [code, setCode] = useState(new Array(6).fill(""));
  const [resolver, setResolver] = useState(null);
  const [verifyPopup, setVerifyPopup] = useState(false);
  const [verificationError, setVerificationError] = useState("");

  const handleLogin = (e) => {
    e.preventDefault();
    if (email === "" || password === "") {
      if (document.getElementById("email").checkValidity() === false) {
        document.getElementById("email").setAttribute("focused", "true");
      }
      if (document.getElementById("password").checkValidity() === false) {
        document.getElementById("password").setAttribute("focused", "true");
      }
    } else {
      dispatch({
        type: actionTypes.SET_LOADING,
        loading: true,
      });
      signInWithEmailAndPassword(auth, email, password)
        .then((result) => {
          dispatch({
            type: actionTypes.SET_LOGIN_USER,
            user: result.user,
            displayName: result.user.displayName,
          });
          dispatch({
            type: actionTypes.SET_LOADING,
            loading: false,
          });
        })
        .catch((error) => {
          switch (error.code) {
            case "auth/multi-factor-auth-required":
              sendVerificationCode(e, error);
              break;
            case "auth/wrong-password":
              setLoginError("Incorrect email or password. Please try again.");
              dispatch({
                type: actionTypes.SET_LOADING,
                loading: false,
              });
              break;
            case "auth/invalid-credential":
              setLoginError("Invalid credentials. Please try again.");
              dispatch({
                type: actionTypes.SET_LOADING,
                loading: false,
              });
              break;
            case "auth/network-request-failed":
              setLoginError(
                "Network error. Please check your internet connection and try again."
              );
              dispatch({
                type: actionTypes.SET_LOADING,
                loading: false,
              });
              break;
            default:
              console.log("Error: ", error);
              setLoginError(
                "Seems like there was some problem logging in. Please try again"
              );
              dispatch({
                type: actionTypes.SET_LOADING,
                loading: false,
              });
              break;
          }
        });
    }
  };

  const sendVerificationCode = async (e, error) => {
    e.preventDefault();
    const recaptchaContainer = document.getElementById("recaptcha-container");
    if (recaptchaContainer) {
      recaptchaContainer.outerHTML = '<div id="recaptcha-container"></div>';
    }
    const recaptchaVerifier = new RecaptchaVerifier(
      auth,
      "recaptcha-container",
      {
        size: "invisible",
      }
    );

    let tempResolver = getMultiFactorResolver(auth, error);
    setResolver(tempResolver);

    const phoneInfoOptions = {
      multiFactorHint: tempResolver.hints[0],
      session: tempResolver.session,
    };
    phoneAuthProvider
      .verifyPhoneNumber(phoneInfoOptions, recaptchaVerifier)
      .then((verificationId) => {
        setVerificationId(verificationId);
        setVerifyPopup(true);
        dispatch({
          type: actionTypes.SET_LOADING,
          loading: false,
        });
      })
      .catch((error) => {
        recaptchaVerifier.clear();
        dispatch({
          type: actionTypes.SET_LOADING,
          loading: false,
        });
        console.log("Error sending verification code: ", error);
      });
  };

  const verifyCode = async (e) => {
    e.preventDefault();
    const recaptchaContainer = document.getElementById("recaptcha-container");
    if (recaptchaContainer) {
      recaptchaContainer.outerHTML = '<div id="recaptcha-container"></div>';
    }
    const recaptchaVerifier = new RecaptchaVerifier(
      auth,
      "recaptcha-container",
      {
        size: "invisible",
      }
    );

    dispatch({
      type: actionTypes.SET_LOADING,
      loading: true,
    });

    try {
      const userCredential = await resolveSignInVerificationProcess(
        code.join(""),
        verificationId,
        resolver
      );
      if (userCredential) {
        dispatch({
          type: actionTypes.SET_LOGIN_USER,
          user: userCredential.user,
          displayName: userCredential.user.displayName,
        });
        dispatch({
          type: actionTypes.SET_LOADING,
          loading: false,
        });
      }
    } catch (error) {
      recaptchaVerifier.clear();
      dispatch({
        type: actionTypes.SET_LOADING,
        loading: false,
      });
      switch (error) {
        case "Invalid verification code":
          setVerificationError("Invalid verification code");
          break;
        default:
          console.log("Error: ", error);
          break;
      }
    }
  };

  const handleSignInWithGoogle = (e) => {
    e.preventDefault();
    dispatch({
      type: actionTypes.SET_LOADING,
      loading: true,
    });
    signInWithPopup(auth, provider)
      .then((result) => {
        dispatch({
          type: actionTypes.SET_LOADING,
          loading: false,
        });
        dispatch({
          type: actionTypes.SET_LOGIN_USER,
          user: result.user,
          displayName: result.user.displayName,
        });
      })
      .catch(async (error) => {
        switch (error.code) {
          case "auth/multi-factor-auth-required":
            sendVerificationCode(e, error);
            break;
          case "auth/wrong-password":
            dispatch({
              type: actionTypes.SET_LOADING,
              loading: false,
            });
            setLoginError("Incorrect email or password. Please try again.");
            break;
          case "auth/cancelled-popup-request":
            dispatch({
              type: actionTypes.SET_LOADING,
              loading: false,
            });
            setLoginError(
              "The popup has been closed by the user before signing in."
            );
            break;
          case "auth/operation-not-allowed":
            dispatch({
              type: actionTypes.SET_LOADING,
              loading: false,
            });
            setLoginError(
              "The identity provider configuration is not found. Please contact the administrator."
            );
            break;
          case "auth/popup-blocked":
            dispatch({
              type: actionTypes.SET_LOADING,
              loading: false,
            });
            setLoginError(
              "The popup window is blocked by the browser. Please enable it to sign in."
            );
            break;
          default:
            dispatch({
              type: actionTypes.SET_LOADING,
              loading: false,
            });
            setLoginError(
              "Seems like there was some problem logging in using the google services. Please try again"
            );
            break;
        }
      });
  };

  const passwordReset = (e) => {
    e.preventDefault();
    if (resetEmail !== "") {
      document.getElementById("reset_email").setAttribute("focused", "false");
      sendPasswordResetEmail(auth, resetEmail)
        .then((result) => {
          // setModalTitle("Password Reset");
          // setModalMessage(
          //   "An email has been sent to your email address with instructions on how to reset your password."
          // );
          // setShow(true);
          setLoginError(
            "An email has been sent to your email address with instructions on how to reset your password."
          );
        })
        .catch((error) => {
          setLoginError(
            "Seems like there was some problem resetting your password. Please try again."
          );
        });
    } else {
      document.getElementById("reset_email").setAttribute("focused", "true");
    }
  };

  const hide_viewPassword = () => {
    const passwordType = document.querySelector("#password");
    try {
      const type =
        passwordType.getAttribute("type") === "password" ? "text" : "password";
      if (type === "text") {
        setActiveEye(true);
      } else {
        setActiveEye(false);
      }
      passwordType.setAttribute("type", type);
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    document.title = "Login | Patel Taxation and Accounting";
  }, []);

  document.getElementById("email")?.addEventListener("input", (event) => {
    if (loginError !== "") {
      setLoginError("");
    }
  });

  document.getElementById("password")?.addEventListener("input", (event) => {
    if (loginError !== "") {
      setLoginError("");
    }
  });

  return verifyPopup ? (
    <div className="login">
      <div id="recaptcha-container"></div>
      <div
        className="model_container"
        style={{ borderRadius: "10px", boxShadow: " 0 0 10px rgba(0,0,0,0.2)" }}
      >
        <VerificationCode
          verifyCode={verifyCode}
          setTogglePopup={setVerifyPopup}
          code={code}
          setCode={setCode}
        />
      </div>
    </div>
  ) : (
    <div className="login">
      <div id="recaptcha-container"></div>
      <div className="loginCard">
        <div className="signIn-container">
          <h1>Sign In</h1>
          <p>
            Sign in to access your account and view your profile, appointments
            and more.
          </p>
          <form className="signIn-form">
            <div className="email-field">
              <label htmlFor="email">Email Address</label>
              <RequiredInput
                id="email"
                inputType="input"
                currValue={email}
                type="email"
                placeholder="Email"
                onChangeVariable={setEmail}
                pattern="^[a-zA-Z0-9+_.-]+@[a-zA-Z0-9.-]+$"
                errorMessage={"Email is a required field"}
                required
              />
            </div>
            <div>
              <label htmlFor="password">Password</label>
              <div className="password-field">
                <RequiredInput
                  id="password"
                  inputType="input"
                  type="password"
                  placeholder="Password"
                  currValue={password}
                  onChangeVariable={setPassword}
                  pattern="(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}"
                  errorMessage={"Password is a required field"}
                  required
                />
                <IconButton
                  onClick={hide_viewPassword}
                  className="password-icon"
                >
                  {activeEye ? <VisibilityIcon /> : <VisibilityOffIcon />}
                </IconButton>
              </div>
            </div>
            <div className="signIn-forgotPassword">
              <a href="#" onClick={passwordReset}>
                Forgot Password?
              </a>
            </div>
            {loginError && <Alert severity="error">{loginError}</Alert>}
            <div className="signIn-actionBtn">
              <Button
                variant="contained"
                className="actionBtn"
                type="submit"
                onClick={handleLogin}
              >
                Sign In
              </Button>
              <Button
                variant="contained"
                className="actionBtn"
                id="google-sign-in-button"
                onClick={handleSignInWithGoogle}
              >
                <img
                  alt="google"
                  src="/images/google.png"
                  width="30"
                  height="30"
                />
                Sign In With Google
              </Button>
            </div>
          </form>
        </div>
        <div className="needToRegister">
          <span>Don't have an account? </span>
          <a href="/signup">Register</a>
        </div>
      </div>
    </div>
  );
}

export default SignIn;
