import React, { useEffect, useState } from "react";
import { useStateValue } from "../../StateProvider";
import "./Dashboard.scss";
import AdminDashboard from "./AdminDashboard";
import RegularDashboard from "./RegularDashboard";
import EmailVerificationReq from "../../Components/Popup/EmailVerificationReq";
import { auth } from "../../firebase";

function DashboardIndex() {
  const [{ user, role, emailVerified }, dispatch] = useStateValue();
  const [emailVerificationPopup, setEmailVerificationPopup] = useState(
    (auth.currentUser && !auth.currentUser.emailVerified) || !emailVerified
  );

  // Can check if the email is verified yet or not...
  useEffect(() => {
    window.scrollTo(0, 0);
    document.title = "Dashboard | Patel Accounting and Taxation";
  }, []);

  return (
    <>
      {role === "Admin" ? <AdminDashboard /> : <RegularDashboard />}{" "}
      {emailVerificationPopup && (
        <EmailVerificationReq setTogglePopup={setEmailVerificationPopup} />
      )}
    </>
  );
}

export default DashboardIndex;
