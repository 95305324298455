import React, { useEffect, useState } from "react";
import "./Home.scss";
import { Swiper, SwiperSlide } from "swiper/react";
import {
  Navigation,
  Pagination,
  Scrollbar,
  A11y,
  Autoplay,
} from "swiper/modules";

// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import OrgInfo from "../../Components/OrgInfo/OrgInfo";
import { AccessTimeFilled, Mail, Phone } from "@mui/icons-material";
import { Button } from "@mui/material";
import RequiredInput from "../../Components/Buttons/RequiredInput";
import { useStateValue } from "../../StateProvider";

function Home() {
  const testimonialData = [
    {
      id: 1,
      name: "Simranjeet Kaur",
      message:
        "Great service from Sunil Patel as he is very experienced in his field. I'm really glad that i chose Sunil patel tax consultant because it's been 2 years i'm getting my tax filed from them and he's been guiding me since day 1 about my tax returns. He is just outstanding in his job.",
    },
    {
      id: 2,
      name: "Milind Patel",
      message:
        "Great service from Mr.Sunilkumar, guided and helped with all the questions regarding taxation and filing for the last 2 years with great success.",
    },
    {
      id: 3,
      name: "Vaibhav Patel",
      message:
        "I've been a client for years and Patel Taxation has always provided excellent service. Highly recommended!",
    },
    {
      id: 4,
      name: "Sarah omamadaga",
      message:
        "Sunil provides great services and a swift process of my tax filing.",
    },
    {
      id: 5,
      name: "Rigved Krishna",
      message:
        "He is serving me from 2015 …… am very much satisfied with the friendly service Patel taxation provides me from past 6 years … And his excellence in work made me a happy customer",
    },
    {
      id: 6,
      name: "Sarah Thompson",
      message:
        "Patel Taxation has been my go-to for tax services. Their expertise and professionalism are unmatched.",
    },
    {
      id: 7,
      name: "Sagar Patel",
      message:
        "I highly recommend Patel Taxation for their exceptional service and attention to detail. They exceeded my expectations.",
    },
    {
      id: 8,
      name: "Priyatham reddy Bitla",
      message:
        "Best person for business….Great sense of humour and outstanding patience….",
    },
    {
      id: 9,
      name: "Mahesh Patel",
      message:
        "I've been using Patel Taxation for years and they consistently deliver accurate and reliable tax services.",
    },
  ];
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState("");
  const inputErrorMessage = {
    name: "Invalid input. Please make sure the input only contains letters.",
    email: "Invalid input. Please provide a valid email address.",
    phoneNumber:
      "Invalid input. Please provide phone number in a proper format. E.g. ###-###-####",
    alphanumeric:
      "Invalid input. Input must only contain alphanumeric characters.",
  };
  const [{ user, role, emailVerified }, dispatch] = useStateValue();

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log("Form submitted");
  };

  useEffect(() => {
    document.title = "Patel Accounting And Taxation - Home";
  }, []);

  return (
    <div className="home">
      {/* Landing page image */}
      <div className="landing-container" id="homepage">
        <Swiper
          className="swiper-container"
          modules={[Navigation, Pagination, Scrollbar, A11y, Autoplay]}
          spaceBetween={50}
          slidesPerGroupSkip={1}
          slidesPerGroup={1}
          slidesPerView={1}
          lazyPreloadPrevNext={true}
          grabCursor={true}
          loop={true}
          navigation
          pagination={{ clickable: true }}
          autoplay={{ delay: 2000, disableOnInteraction: true }}
        >
          <SwiperSlide>
            <div className="landingImage-container">
              <img src="/images/card1.png" alt="lander" />
              <div className="landing-message">
                <h1>25 Years of Industrial Experience</h1>
              </div>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="landingImage-container">
              <img src="/images/card2.png" alt="lander" />
              <div className="landing-message">
                <h1>Specialization In Students Consultation</h1>
              </div>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="landingImage-container">
              <img src="/images/card3.png" alt="lander" />
              <div className="landing-message">
                <h1>Year Round Office - Permanent Location</h1>
              </div>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="landingImage-container">
              <img src="/images/card4.png" alt="lander" />
              <div className="landing-message">
                <h1>Maximum Refund Possible</h1>
              </div>
            </div>
          </SwiperSlide>
        </Swiper>
      </div>

      {/* Provide Service By */}
      <div className="ourServices-container">
        <div className="section-header">Provide Service By</div>
        <div className="service-container" id="provideService">
          <div className="provideService">
            <img src="/images/card1.png" alt="taxation" />
            <h3>In-Person</h3>
            <p>
              Find an open nearby office or connect with a remote tax expert.
            </p>
          </div>
          <div className="provideService">
            <img src="/images/card2.png" alt="accounting" />
            <h3>Remote File</h3>
            <p>
              You can get your taxes prepared remotely by a tax professional.
            </p>
          </div>
          <div className="provideService">
            <img src="/images/card3.png" alt="consulting" />
            <h3>Drop-Off</h3>
            <p>
              Don't want to sit and wait? Drop your tax documents off at one of
              our locations.
            </p>
          </div>
        </div>
      </div>

      {/* Our Services */}
      <div className="ourServices-container" id="services">
        <div className="section-header">Services</div>
        <h1>
          Make all the right money moves by our
          <span style={{ color: "red" }}> services</span>
        </h1>
        <div
          className="service-container"
          style={{
            display: "flex",
            flexWrap: "wrap",
            gap: "35px",
            justifyContent: "space-between",
          }}
        >
          <div className="service">
            <img
              src="/images/card1.png"
              alt="taxation"
              style={{ height: "90%" }}
            />
            <h3>Personal Tax Return</h3>
          </div>
          <div className="service">
            <img
              src="/images/card2.png"
              alt="accounting"
              style={{ height: "90%" }}
            />
            <h3>Corporate Tax Return</h3>
          </div>
          <div className="service">
            <img
              src="/images/card3.png"
              alt="consulting"
              style={{ height: "90%" }}
            />
            <h3>Business Incorporation</h3>
          </div>
          <div className="service">
            <img
              src="/images/card4.png"
              alt="consulting"
              style={{ height: "90%" }}
            />
            <h3>Accounting</h3>
          </div>
        </div>
      </div>

      {/* Refer Us section */}
      <div className="cta-section bg-rust">
        <div className="cta-section-content">
          <h1>Refer Us</h1>
          <div className="cta-section-information">
            <OrgInfo
              Logo={Phone}
              Size="medium"
              Title="Call Us"
              InfoMessage={() => {
                return (
                  <span style={{ display: "flex", gap: "5px" }}>
                    <a href="tel:(647) 870-7907">(647) 870-7907</a>
                    <p>or</p>
                    <a href="tel:(365) 443-3444">(365) 443-3444</a>
                  </span>
                );
              }}
            />
            <OrgInfo
              Logo={Mail}
              Size="medium"
              Title="Email Us"
              InfoMessage={() => {
                return (
                  <a href="mailto:info@pateltaxation.ca">
                    info@pateltaxation.ca
                  </a>
                );
              }}
            />
            <OrgInfo
              Logo={AccessTimeFilled}
              Size="medium"
              Title="Hours of Operations"
              InfoMessage={() => {
                return (
                  <>
                    <p>
                      Special Hours - 10am - 9pm (From 1st Feb - 30th April)
                    </p>
                    <p>Regular Hours - 10am - 5pm or by appointment</p>
                  </>
                );
              }}
            />
          </div>
        </div>
      </div>

      {/* About Us */}
      <div className="cta-section bg-hexagon">
        <div className="aboutUs-container">
          <div className="wrapper">
            <div className="section-header">About Us</div>
            <h1>
              We are here to manage your finance with{" "}
              <span style={{ color: "red" }}>experience</span>
            </h1>
            <div className="aboutUs-message">
              <p>
                We are a team of professional accountants and tax consultants
                serving clients in the Greater Toronto Area. We have over 20
                years of experience in accounting and taxation. We provide tax
                services for individuals, corporations, estates, trusts and
                not-for-profit organizations. We provide accounting services for
                small to medium size businesses. We provide consulting services
                for small to medium size businesses.
              </p>
            </div>
            <div className="aboutUs-stats">
              <div className="stats">
                <img src="/images/leaf.png" alt="leaf" />
                <h3>1850+</h3>
                <p>Satisfied Clients</p>
              </div>
              <div className="stats">
                <img src="/images/leaf.png" alt="leaf" />
                <h3>2000+</h3>
                <p>Completed Works</p>
              </div>
              <div className="stats">
                <img src="/images/leaf.png" alt="leaf" />
                <h3>25</h3>
                <p>Years of Experience</p>
              </div>
            </div>
          </div>
          <div className="aboutUs-img">
            <img src="/images/abou-us-image.jpg" alt="about-us" />
          </div>
        </div>
      </div>

      {/* Location */}
      <div className="cta-section bg-rust">
        <div className="org-location">
          <div className="section-header">Location</div>
          <div className="location-map">
            <iframe
              title="Organization Location"
              width="100%"
              height="500px"
              loading="lazy"
              style={{ border: "none" }}
              allowFullScreen
              referrerPolicy="no-referrer-when-downgrade"
              // src={`https://www.google.com/maps/embed/v1/place?key=AIzaSyBleRL9HNiKTpvqus-3ZnPJB0uw_ALLAi0&q=PatelAccountingAndTaxation&center=37.7749,-122.4194&q=SecondLocation&center=34.0522,-118.2437`}
              src="https://www.google.com/maps/d/u/0/embed?mid=1lh-8dWWM1BErbLLGI3VxzrvaZ88zj_U&ehbc=2E312F&noprof=1"
              className="mapContainer"
            ></iframe>
          </div>
        </div>
      </div>

      {/* Testimonial section */}
      <div className="cta-section bg-maps">
        <div className="testimonial-container">
          <div className="section-header">Testimonial</div>
          <h1>
            Some from our <span style={{ color: "red" }}>clients</span>
          </h1>
          <Swiper
            className="swiper-container"
            modules={[Pagination, Scrollbar, A11y, Autoplay]}
            spaceBetween={50}
            slidesPerView={1}
            slidesPerGroup={1}
            slidesPerGroupSkip={1}
            lazyPreloadPrevNext={true}
            grabCursor={true}
            loop={true}
            pagination={{ clickable: true }}
            autoplay={{ delay: 4000 }}
          >
            {testimonialData.map((item) => {
              return (
                <SwiperSlide key={item.id}>
                  <div className="testimonial-card">
                    <p>{item.message}</p>
                    <h3>{item.name}</h3>
                  </div>
                </SwiperSlide>
              );
            })}
          </Swiper>
        </div>
      </div>

      {/* Looking for an Accounting Firm to Help Grow Your Business?*/}
      <div className="cta-section bg-secondary" id="contactUs">
        <div className="cta-section-content" id="additionalInfo">
          <h1>Looking for an Accounting Firm to Help Grow Your Business?</h1>
          <div className="cta-section-information">
            <OrgInfo
              Logo={Phone}
              Size="medium"
              Title="Call Us"
              InfoMessage={() => {
                return (
                  <span style={{ display: "flex", gap: "5px" }}>
                    <a href="tel:(647) 870-7907">(647) 870-7907</a>
                    <p>or</p>
                    <a href="tel:(365) 443-3444">(365) 443-3444</a>
                  </span>
                );
              }}
            />
            <OrgInfo
              Logo={Mail}
              Size="medium"
              Title="Email Us"
              InfoMessage={() => {
                return (
                  <a href="mailto:info@pateltaxation.ca">
                    info@pateltaxation.ca
                  </a>
                );
              }}
            />
            <OrgInfo
              Logo={AccessTimeFilled}
              Size="medium"
              Title="Hours of Operations"
              InfoMessage={() => {
                return (
                  <>
                    <p>
                      Special Hours - 10am - 9pm (From 1st Feb - 30th April)
                    </p>
                    <p>Regular Hours - 10am - 5pm or by appointment</p>
                  </>
                );
              }}
            />
          </div>
        </div>
      </div>

      {/* Contact Us */}
      <div
        className="sectioned-container bg-contact"
        style={{
          marginTop: "-35px",
          color: "white",
        }}
      >
        <div className="contactUs-container">
          <div className="section-header">Contact Us</div>
          <h1>
            Request a <span style={{ color: "red" }}>call back?</span>
          </h1>
          <form className="contactUs-form">
            <label htmlFor="name">
              Name
              <RequiredInput
                id="name_contact"
                inputType="input"
                type="text"
                currValue={name}
                placeholder="Name"
                onChangeVariable={setName}
                pattern="^[A-Z][a-z][A-Za-z ]*$"
                errorMessage={inputErrorMessage["name"]}
                required
              />
            </label>
            <label htmlFor="email">
              Email Address
              <RequiredInput
                id="email_contact"
                inputType="input"
                type="email"
                currValue={email}
                placeholder="Email"
                onChangeVariable={setEmail}
                pattern="^([a-zA-Z0-9_\-\.]+)@([a-zA-Z0-9_\-\.]+)\.([a-zA-Z]{2,5})$"
                errorMessage={inputErrorMessage["email"]}
                required
              />
            </label>
            <label htmlFor="phone">
              Phone
              <RequiredInput
                id="phone_contact"
                inputType="input"
                type="text"
                currValue={phone}
                placeholder="e.g. 123-456-7890"
                onChangeVariable={setPhone}
                pattern="[0-9]{3}-[0-9]{3}-[0-9]{4}"
                errorMessage={inputErrorMessage["phoneNumber"]}
                required
              />
            </label>
            <label htmlFor="subject">
              Subject
              <RequiredInput
                id="subject_contact"
                inputType="input"
                type="text"
                currValue={subject}
                placeholder="e.g. Tickets"
                onChangeVariable={setSubject}
                pattern="^[A-Z][a-z][A-Za-z ]*$"
                errorMessage={inputErrorMessage["name"]}
                required
              />
            </label>
            <label htmlFor="message">
              Message
              <RequiredInput
                id="message_contact"
                inputType="textarea"
                type="text"
                currValue={message}
                placeholder="e.g. When are the tickets releasing for the next event?"
                onChangeVariable={setMessage}
                pattern="^[A-Za-z0-9 ]*$"
                errorMessage={inputErrorMessage["alphanumeric"]}
                required
              />
            </label>
            <Button
              variant="contained"
              color="secondary"
              type="submit"
              onClick={handleSubmit}
            >
              Submit
            </Button>
          </form>
        </div>
      </div>
    </div>
  );
}

export default Home;
